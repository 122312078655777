<template>
  <div>
    <b-modal
      id="user-activate"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`${$t('Activate')} ${editId.firstName}`"
      hide-footer
    >

      <p>{{ $t('YouAreActivating') }} <b>{{ editId.firstName }}</b></p>
      <b-button
        type="button"
        variant="none"
        class="buttonSubmit"
        @click="onSubmit"
      >
        Activate
      </b-button>
      <b-button
        type="reset"
        variant="danger"
        class="buttonCancel"
        @click="onCancel"
      >
        {{ $t('Cancel') }}
      </b-button>
    </b-modal></div>
</template>

<script>

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    editId: {
      type: Object,
    },
    editToggle: {
      type: Boolean,
    },
  },
  data() {
    return {
      filters: {
        page: 1,
        pageSize: 20,
        query: '',
        ...this.$route.query,
      },
      totalItems: '',
      options: [],
      selected: null,
      button: false,
      test: this.editToggle,
    }
  },
  methods: {
    onSubmit() {
      this.$emit('objData', this.editId.userId)
      this.$refs.modal.hide()
    },
    onCancel() {
      this.$refs.modal.hide()
      // this.isActive = true
      // this.editToggle = !this.editToggle
    },
  },
}
</script>

<style lang="scss" scoped>
  form {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .deactivate-modal__select {
    display: flex;
    flex-direction: column;
    select {
      margin-bottom: 10px;
      padding: 10px;
    }
    button {
      align-self: end;
    }
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
  .my-class {
  background: black;
  font-weight: bold;
}

  // .my-second-class > .modal-dialog > .modal-content > .modal-header {
  // background: black;
  // color: white;
  // }
</style>
