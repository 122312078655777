<template>
  <div>
    <b-modal
      id="add-user"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('SubmitNewUser')"
      size="lg"
      hide-footer
      @close="onCancel"
    >
      <b-tabs
        v-model="tabIndex"
        card
      >
        <b-tab
          :title="$t('EmployeeUserInfo')"
          active
        >
          <template #title>
            <strong style="color: #0071AE">{{ $t("EmployeeUserInfo") }}</strong>
          </template>
          <b-card-text><b-form>
            <b-form-group
              id="input-group-6"
              style="font-weight: bold"
              class="mb-0"
            />
            <pre />
            <b-form-group
              id="input-group-5"
              :label="`${$t('EmployeeSalutation')}:`"
              label-for="input-2"
            >
              <vue-select
                id="input-5"
                v-model="$v.formUser.newUserRequestDto.salutation.$model"
                required
                :options="['MR', 'MRS']"
                :placeholder="$t('Select')"
                aria-describedby="input-1-live-feedback"
              />
              <span
                v-show="formSubmitted && !$v.formUser.newUserRequestDto.salutation.required"
                style="color: red"
              >{{ $t("AddNew35") }}</span>
            </b-form-group>
            <b-form-group
              id="input-group-4"
              :label="`${$t('EmployeeDateBirth')}:`"
              label-for="input-9"
              style="height: 62px"
            >
              <!-- <flat-pickr
                v-model="formUser.newUserRequestDto.dateOfBirth"
                class="form-control"
                :config="configStart"
                placeholder="Select Date"
                /> -->
              <date-picker
                v-model="dateOfBirth"
                style="width: 100%"
                value-type="format"
                :placeholder="$t('Select')"
                :format="'DD-MM-YYYY'"
              />
              <span
                v-show="formSubmitted && !$v.dateOfBirth.required"
                style="color: red"
              >{{ $t("AddNew33") }}</span>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              :label="`${$t('EmployeeFirstName')}:`"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="$v.formUser.newUserRequestDto.firstName.$model"
                :state="validateState('firstName')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_be_at_least_3_letters")
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              :label="`${$t('EmployeeLastName')}:`"
              label-for="input-1"
            >
              <b-form-input
                id="input-2"
                v-model="$v.formUser.newUserRequestDto.lastName.$model"
                :state="validateState('lastName')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_be_at_least_3_letters")
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-3"
              :label="`${$t('EmployeeEmail')}:`"
              label-for="input-9"
            >
              <b-form-input
                id="input-3"
                v-model="$v.formUser.newUserRequestDto.email.$model"
                :state="validateState('email')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_be_at_least_3_letters")
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-6"
              :label="`${$t('EmployeePhoneN')}:`"
              label-for="input-9"
            >
              <vue-phone-number-input
                id="input-6"
                v-model="$v.formUser.newUserRequestDto.phoneNumber.$model"
                default-country-code="CH"
                :error="
                  isPhoneNumberValid == null ? false : isPhoneNumberValid == false ? true : false
                "
                :preferred-countries="['AL', 'XK', 'DE']"
                @update="getFormattedNumber"
              />
              <!-- <span
                v-show="formSubmitted && !$v.formUser.newUserRequestDto.phoneNumber.required"
                style="color: red"
              >{{ $t("AddNew33") }}</span> -->
            </b-form-group>
            <b-form-group
              id="input-group-5"
              :label="`${$t('EmployeeNationality')}:`"
              label-for="input-2"
            >
              <vue-select
                v-model="$v.formUser.newUserRequestDto.nationality.$model"
                :options="countryOptions"
                label="name"
              />
              <span
                v-show="formSubmitted && !$v.formUser.newUserRequestDto.nationality.required"
                style="color: red"
              >{{ $t("AddNew33") }}</span>
            </b-form-group>
            <b-form-group
              v-if="formUser.newUserRequestDto.nationality != 'Switzerland'"
              id="input-group-5"
              :label="`${$t('EmployeeResidenceP')}:`"
              label-for="input-2"
            >
              <vue-select
                id="input-5"
                v-model="formUser.newUserRequestDto.residencePermit"
                required
                :options="['B', 'C', 'Ci', 'G', 'L', 'F', 'N', 'S']"
                style="z-index: 0"
                :placeholder="$t('Select')"
                aria-describedby="input-1-live-feedback"
              />
              <!-- <span
                v-show="formSubmitted && !$v.formUser.newUserRequestDto.residencePermit.required"
                style="color: red"
              >{{ $t("AddNew33") }}</span> -->
            </b-form-group>
            <b-form-group
              id="input-group-5"
              :label="`${$t('EmployeeMaritalS')}:`"
              label-for="input-2"
            >
              <vue-select
                id="input-5"
                v-model="$v.formUser.newUserRequestDto.maritalStatus.$model"
                required
                :options="[
                  { value: 'Single', label: $t('Single') },
                  { value: 'Married', label: $t('Married') },
                  { value: 'Divorced', label: $t('Divorced') },
                  { value: 'Seperated', label: $t('Seperated') },
                ]"
                :reduce="(e) => e.value"
                :placeholder="$t('Select')"
                aria-describedby="input-1-live-feedback"
              />
              <span
                v-show="formSubmitted && !$v.formUser.newUserRequestDto.maritalStatus.required"
                style="color: red"
              >{{ $t("AddNew33") }}</span>
            </b-form-group>
          </b-form>
          </b-card-text>
        </b-tab>
        <b-tab
          :disabled="
            formSubmitted ||
              !$v.formUser.newUserRequestDto.salutation.required ||
              !$v.dateOfBirth.required ||
              !$v.formUser.newUserRequestDto.maritalStatus.required ||
              !$v.formUser.newUserRequestDto.firstName.required ||
              !$v.formUser.newUserRequestDto.lastName.required ||
              !$v.formUser.newUserRequestDto.email.required ||
              !$v.formUser.newUserRequestDto.phoneNumber.required ||
              !$v.formUser.newUserRequestDto.nationality.required
          "
        >

          <template #title>
            <strong style="color: #0071AE">{{ $t("EmployeeAddress") }}</strong>
          </template>
          <b-card-text><b-form>
            <b-form-group
              id="input-group-11"
              style="font-weight: bold"
              class="mb-0"
            />
            <pre />
            <b-form-group
              id="input-group-3"
              label-for="input-3"
              :label="`${$t('EmployeeStreet')}`"
            >
              <b-form-input
                id="input-11"
                v-model="$v.formUser.newAddressRequestDto.street.$model"
                :placeholder="`${$t('Street')}`"
                :state="validateState2('street')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_contains_numbers")
              }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="input-group-6"
              label-for="input-6"
              :label="`${$t('EmployeeHouseNumber')}`"
            >
              <b-form-input
                id="input-12"
                v-model="$v.formUser.newAddressRequestDto.houseNumber.$model"
                placeholder="House Number"
                :state="validateState2('houseNumber')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_contains_numbers")
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-13"
              label-for="input-6"
              :label="`${$t('EmployeeDoorNumber')}`"
            >
              <b-form-input
                id="input-13"
                v-model="$v.formUser.newAddressRequestDto.doorNumber.$model"
                placeholder="Door Number"
                :state="validateState2('doorNumber')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_contains_numbers")
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-6"
              label-for="input-6"
              :label="`${$t('EmployeePostalC')}`"
            >
              <b-form-input
                id="input-14"
                v-model="$v.formUser.newAddressRequestDto.postalCode.$model"
                :placeholder="`${$t('PostalCode')}`"
                :state="validateState2('postalCode')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_be_at_least_5_characters")
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-5"
              label-for="input-5"
              :label="`${$t('EmployeeCity')}`"
            >
              <b-form-input
                id="input-15"
                v-model="$v.formUser.newAddressRequestDto.city.$model"
                :state="validateState2('city')"
                :placeholder="`${$t('City')}`"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_be_at_least_3_letters")
              }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="input-group-"
              label-for="input-7"
              :label="`${$t('EmployeeCountry')}`"
            >
              <b-form-input
                id="input-16"
                v-model="$v.formUser.newAddressRequestDto.country.$model"
                :state="validateState2('country')"
                :placeholder="`${$t('Country')}`"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_be_at_least_3_letters")
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-5"
              :label="`${$t('EmployeeCanton')}:`"
              label-for="input-2"
            >
              <vue-select
                id="input-122"
                v-model="$v.canton.$model"
                :options="getCantons"
                label="name"
                :placeholder="$t('Select')"
              />
              <span
                v-show="formSubmitted && !$v.canton.required"
                style="color: red"
              >{{ $t("AddNew33") }}</span>
            </b-form-group>
          </b-form></b-card-text>
        </b-tab>
        <b-tab
          :disabled="
            formSubmitted ||
              !$v.formUser.newUserRequestDto.salutation.required ||
              !$v.dateOfBirth.required ||
              !$v.formUser.newUserRequestDto.maritalStatus.required ||
              !$v.formUser.newUserRequestDto.firstName.required ||
              !$v.formUser.newUserRequestDto.lastName.required ||
              !$v.formUser.newUserRequestDto.email.required ||
              !$v.formUser.newUserRequestDto.phoneNumber.required ||
              !$v.formUser.newUserRequestDto.nationality.required ||
              !$v.formUser.newAddressRequestDto.street.required ||
              !$v.formUser.newAddressRequestDto.houseNumber ||
              !$v.formUser.newAddressRequestDto.doorNumber ||
              !$v.formUser.newAddressRequestDto.postalCode.required ||
              !$v.formUser.newAddressRequestDto.city ||
              !$v.formUser.newAddressRequestDto.country.required ||
              !$v.canton.required
          "
        >

          <template #title>
            <strong style="color: #0071AE">{{ $t("EmployeeDocuments") }}</strong>
          </template>
          <div style="display: flex; flex-direction: column;">
            <div style="display: flex; gap: 10px;">
              <AddFile
                ref="addFile1"
                :folder="'OrderPhotos'"
                :label-text="$t('EmployeePassport')"
                :file-data-type="'Passport'"
                :file-name="formUser.newDocumentRequestDto"
              />
              <AddFile
                ref="addFile2"
                :folder="'OrderPhotos'"
                :label-text="$t('AddNew39')"
                :file-data-type="'ResidencePermit'"
                :file-name="formUser.newDocumentRequestDto"
              />
            </div>
            <div style="width: 100%;display:flex;align-items: center;gap: 10px;">
              <b-form-group
                id="input-group-"
                label-for="input-7"
                :label="`${$t('EmployeeAHV')}`"
              >
                <b-form-input
                  id="input-16"
                  v-model="$v.formUser.newUserRequestDto.socialNumber.$model"
                  :state="validateState('socialNumber')"
                  :placeholder="`${$t('Country')}`"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <AddFile
                ref="addFile3"
                :folder="'OrderPhotos'"
                :label-text="$t('EmployeeAHV')"
                :file-data-type="'SocialNumber'"
                :file-name="formUser.newDocumentRequestDto"
              />
            </div>
          </div>
        </b-tab>
        <b-tab
          :disabled="
            formSubmitted ||
              !$v.formUser.newUserRequestDto.salutation.required ||
              !$v.dateOfBirth.required ||
              !$v.formUser.newUserRequestDto.maritalStatus.required ||
              !$v.formUser.newUserRequestDto.firstName.required ||
              !$v.formUser.newUserRequestDto.lastName.required ||
              !$v.formUser.newUserRequestDto.email.required ||
              !$v.formUser.newUserRequestDto.phoneNumber.required ||
              !$v.formUser.newUserRequestDto.nationality.required ||
              !$v.formUser.newAddressRequestDto.street.required ||
              !$v.formUser.newAddressRequestDto.houseNumber ||
              !$v.formUser.newAddressRequestDto.doorNumber ||
              !$v.formUser.newAddressRequestDto.postalCode.required ||
              !$v.formUser.newAddressRequestDto.city ||
              !$v.formUser.newAddressRequestDto.country.required ||
              !$v.canton.required
          "
        >

          <template #title>
            <strong style="color: #0071AE">{{ $t("EmployeeWorkInfo") }}</strong>
          </template>
          <b-card-text>
            <b-form>
              <b-form-group
                id="input-group-6"
                style="font-weight: bold"
                class="mb-0"
              />
              <pre />
              <b-form-group
                id="input-group-8"
                :label="$t('VacationEmployeeT')"
                label-for="input-25"
              >
                <vue-select
                  id="input-8"
                  v-model="$v.formUser.newUserRequestDto.employeeType.$model"
                  :options="[
                    {value:'Internal', label:$t('Internal')},
                    {value:'External',label:$t('External')},
                  ]"
                  :reduce="(e) => e.value"
                  aria-describedby="input-1-live-feedback"
                />
                <span
                  v-show="formSubmitted && !$v.formUser.newUserRequestDto.employeeType.required"
                  style="color: red"
                >{{ $t("AddNew33") }}</span>
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-7"
                :label="$t('VacationRole')"
                label-for="input-26"
              >
                <vue-select
                  id="input-7"
                  v-model="$v.formUser.newUserRequestDto.personRole.$model"
                  :options="roleOptions"
                />
                <span
                  v-show="formSubmitted && !$v.formUser.newUserRequestDto.personRole.required"
                  style="color: red"
                >{{ $t("AddNew33") }}</span>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                :label="$t('EmployeeSalary')"
                label-for="input-27"
              >
                <b-form-input
                  id="input-27"
                  v-model.number="$v.formUser.newOverviewRequestDto.salary.$model"
                  :state="validateState3('salary')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_be_at_least_3_letters")
              }}</b-form-invalid-feedback>
              <b-form-checkbox
                id="checkbox-1"
                v-model="formUser.newOverviewRequestDto.thirteenSalary"
                name="checkbox-1"
                :value="true"
                :unchecked-value="false"
              >
                {{ $t("EmployeeThirteen") }}
              </b-form-checkbox>
              <b-form-group
                id="input-group-1"
                :label="$t('EmployeeSalaryChanges')"
                label-for="input-27"
              >
                <b-form-input
                  id="input-27"
                  v-model.number="$v.formUser.newOverviewRequestDto.salaryChanges.$model"
                  :state="validateState3('salaryChanges')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                :label="$t('EmployeeChildAllowances')"
                label-for="input-27"
              >
                <b-form-input
                  id="input-27"
                  v-model.number="$v.formUser.newOverviewRequestDto.childAllowances.$model"
                  :state="validateState3('childAllowances')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                :label="$t('EmployeeStartingDay')"
                label-for="input-1"
              >
                <!-- <flat-pickr
                v-model="formUser.newOverviewRequestDto.startDate"
                class="form-control"
                :config="configStart"
                placeholder="Select Date"
              />
              <span
                v-show="formSubmitted && !$v.formUser.newOverviewRequestDto.startDate.required"
                style="color: red"
              >{{ $t("AddNew33") }}</span> -->
                <date-picker
                  v-model="$v.time1.$model"
                  style="width: 100%"
                  value-type="format"
                  placeholder="Select Date"
                  :format="'DD-MM-YYYY'"
                />
                <span
                  v-show="formSubmitted && !$v.time1.required"
                  style="color: red"
                >{{ $t("AddNew33") }}</span>
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                :label="$t('EmployeeEndingDay')"
                label-for="input-1"
              >
                <date-picker
                  v-model="$v.time2.$model"
                  style="width: 100%"
                  value-type="format"
                  :placeholder="$t('Select')"
                  :format="'DD-MM-YYYY'"
                />
                <span
                  v-show="formSubmitted && !$v.time2.required"
                  style="color: red"
                >{{ $t("AddNew33") }}</span>
                <!-- <flat-pickr
                v-model="formUser.newOverviewRequestDto.endDate"
                class="form-control"
                :config="configStart"
                placeholder="Select Date"
              />
              <p
                v-if="showError"
                style="color:#ff9966 ; font-size: smaller;"
              >
                Important! <br> Endind date should be after starting date!
              </p> -->
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                :label="$t('EmployeeWorkload')"
                label-for="input-23"
              >
                <vue-select
                  id="input-22"
                  v-model="$v.formUser.newOverviewRequestDto.workload.$model"
                  :options="[20, 40, 50, 60, 80, 100]"
                  aria-describedby="input-1-live-feedback"
                />
                <span
                  v-show="formSubmitted && !$v.formUser.newOverviewRequestDto.workload.required"
                  style="color: red"
                >{{ $t("AddNew33") }}</span>
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                :label="$t('VacationDays')"
                label-for="input-24"
              >
                <b-form-input
                  id="input-23"
                  v-model.number="$v.formUser.newUserRequestDto.numberOfVacationDays.$model"
                  aria-describedby="input-1-live-feedback"
                  type="number"
                  :state="validateState('numberOfVacationDays')"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
            </b-form></b-card-text>
        </b-tab></b-tabs>
      <b-form>

        <b-form
          class="form2"
          style="margin-top:10px"
        >
          <b-button
            v-if="tabIndex == 3"
            type="button"
            variant="none"
            class="buttonSubmit"
            :disabled="loading || loading2 || loading3"
            style="background: transparent linear-gradient(53deg, #0071AE 0%, #55B94A 100%) 0% 0% no-repeat padding-box;display: flex; align-items: center; justify-content: center;"
            @click="onSubmitAddUser"
          >
            <div
              v-if="loading"
              style="margin-right: 5px;"
              class="spinner-border spinner-border-sm"
            />
            <!-- <p style="margin-left: 5px;"> -->
            {{ $t('VacationSubmit') }}

            <!-- </p> -->
          </b-button>
          <b-button
            v-else
            class="buttonSubmit"
            style="width: 100%; margin-left: 15px;"
            :disabled="
              tabIndex == 0
                ? formSubmitted ||
                  !$v.formUser.newUserRequestDto.salutation.required ||
                  !$v.dateOfBirth.required ||
                  !$v.formUser.newUserRequestDto.maritalStatus.required ||
                  !$v.formUser.newUserRequestDto.firstName.required ||
                  !$v.formUser.newUserRequestDto.lastName.required ||
                  !$v.formUser.newUserRequestDto.email.required ||
                  !$v.formUser.newUserRequestDto.phoneNumber ||
                  !$v.formUser.newUserRequestDto.nationality.required
                : tabIndex == 1
                  ? formSubmitted ||
                    !$v.formUser.newAddressRequestDto.street.required ||
                    !$v.formUser.newAddressRequestDto.houseNumber ||
                    !$v.formUser.newAddressRequestDto.doorNumber ||
                    !$v.formUser.newAddressRequestDto.postalCode.required ||
                    !$v.formUser.newAddressRequestDto.city ||
                    !$v.formUser.newAddressRequestDto.country.required ||
                    !$v.canton.required
                  : tabIndex == 2
                    ? formSubmitted
                    : formSubmitted ||
                      !$v.formUser.newUserRequestDto.employeeType.required ||
                      !$v.formUser.newUserRequestDto.personRole.required ||
                      !$v.formUser.newOverviewRequestDto.salary.required ||
                      !$v.formUser.newOverviewRequestDto.salaryChanges.required ||
                      !$v.formUser.newOverviewRequestDto.childAllowances.required ||
                      !$v.formUser.newOverviewRequestDto.startDate.required ||
                      !$v.formUser.newOverviewRequestDto.workload.required ||
                      !$v.formUser.newUserRequestDto.numberOfVacationDays.required
            "
            @click="tabIndex++"
          >
            {{ $t("EmployeeNext") }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 0px"
            @click="onCancel"
          >
            {{ $t("VacationCancel") }}
          </b-button>
        </b-form>
        <p
          v-if="tabIndex == 3"
          v-show="showFileError || showFileError2 || showFileError3"
          :class="{ 'hidden': showFileError || showFileError2 || showFileError3}"
          style="color:#ff0000 ;"
        >{{ $t('CheckAllTabsAgain') }}</p>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CircularJSON from 'circular-json';
// import flatPickr from 'vue-flatpickr-component';
// import flatPickr from 'vue-flatpickr-component';
import { validationMixin } from 'vuelidate';
import AWS from 'aws-sdk';
import moment from 'moment';
import {
  required, email, minLength, maxLength, alphaNum, numeric,
} from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
import AddFile from '@/components/AddFile.vue'
import { AWSurl } from '../../../domainConfig';
import 'vue2-datepicker/index.css';

// import {
//   required, dateFormat, minLength, alpha, minValue, numeric, alphaNum, email,
// } from 'vuelidate/lib/validators';

export default {
  components: {
    DatePicker,
    AddFile,
  },
  mixins: [validationMixin],
  data() {
    return {
      selectedCountry: null,
      showFileError: false,
      loading: false,
      showFileError2: false,
      loading2: false,
      showFileError3: false,
      loading3: false,
      countryOptions: [
        'Switzerland',
        'Germany',
        'Italy',
        'Portugal',
        'Spain',
        'France',
        'Austria',
        'Kosovo',
        'Albania',
        'North Macedonia',
        'Serbia',
        'Croatia', // Kosovo's ISO 3166-1 alpha-2 code is 'XK'
      ],
      tabIndex: 1,
      showError: false,
      configStart: {
        altInput: true,
        altFormat: 'd-m-Y',
        minDate: new Date(),
        dateFormat: 'Y-m-d',
      },
      isPhoneNumberValid: null,
      roleOptions: ['CEO', 'Manager', 'TeamLeader', 'CoTeamLeader', 'Employee'],
      s3: null,
      bucketName: AWSurl.bucketName,
      canton: [],
      fileDataPassport: [],
      formSubmitted: false,
      fileDataAufenthaltsbewilligung: [],
      fileDataAHV: [],
      dateOfBirth: null,
      time1: null,
      time2: '01-01-2099',
      formUser: {
        newUserRequestDto: {
          firstName: '',
          lastName: '',
          salutation: '',
          personRole: '',
          countryCode: '',
          phoneNumber: '',
          email: '',
          socialNumber: '',
          dateOfBirth: '',
          maritalStatus: '',
          residencePermit: '',
          nationality: '',
          employeeType: '',
          numberOfVacationDays: 0,
        },
        newAddressRequestDto: {
          street: '',
          houseNumber: '',
          doorNumber: '',
          postalCode: '',
          city: '',
          country: '',
          countryState: '',
        },
        newOverviewRequestDto: {
          startDate: '',
          endDate: '2099-01-01',
          workload: 0,
          salary: 0,
          thirteenSalary: false,
          childAllowances: 0,
          salaryChanges: 0,
        },
        newDocumentRequestDto: [],
      },
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
      salutation: ['Mr.', 'Ms.', 'BA.', 'BSc.', 'DI.', 'MA.', 'MSc.', 'MBA.', 'PhD.'],
    };
  },
  validations: {
    formUser: {
      newUserRequestDto: {
        firstName: {
          required,
          minLength: minLength(3),
        },
        lastName: {
          required,
          minLength: minLength(3),
        },
        salutation: {
          required,
        },
        personRole: {
          required,
        },
        countryCode: {
          required,
        },
        phoneNumber: {
          required,
        },
        email: {
          required,
          email,
        },
        socialNumber: {
          // numeric,
          required,
        },
        dateOfBirth: {
          required,
        },
        maritalStatus: {
          required,
        },
        // residencePermit: {
        //   required,
        // },
        nationality: {
          required,
        },
        employeeType: {
          required,
        },
        numberOfVacationDays: {
          required,
        },
      },
      newAddressRequestDto: {
        street: {
          required,
          minLength: minLength(3),
        },
        houseNumber: {
          minLength: minLength(1),
          numeric,
        },
        postalCode: {
          required,
          alphaNum,
          numeric,
        },
        doorNumber: {
          numeric,
          alphaNum,
        },
        city: {
          minLength: minLength(3),
        },
        country: {
          required,
          minLength: minLength(3),
        },
      },
      newOverviewRequestDto: {
        salary: {
          required,
        },
        salaryChanges: {
          required,
        },
        childAllowances: {
          required,
        },
        startDate: {
          required,
        },
        endDate: {
          required,
        },
        workload: {
          required,
        },
      },
      // newDocumentRequestDto: {},
    },
    canton: {
      required,
    },
    myFiles: {
      // required,
      maxLength: maxLength(1),
    },
    dateOfBirth: {
      required,
    },
    time1: {
      required,
    },
    time2: {
      required,
    },
  },
  computed: {
    ...mapGetters([
      'getCantons',
      // 'getFilteredMaterials',
    ]),
  },
  watch: {
    // eslint-disable-next-line func-names
    'formUser.newOverviewRequestDto.startDate': {
      handler(value) {
        if (value > this.formUser.newOverviewRequestDto.endDate) {
          this.showError = true;
        } else {
          this.showError = false;
        }
      },
      immediate: true,
    },
    'formUser.newOverviewRequestDto.endDate': {
      handler(value) {
        if (value < this.formUser.newOverviewRequestDto.startDate) {
          this.showError = true;
        } else {
          this.showError = false;
        }
      },
      immediate: true,
    },
    'formUser.newUserRequestDto.nationality': {
      handler(value) {
        if (value === 'Switzerland') {
          this.formUser.newUserRequestDto.residencePermit = '';
        }
      },
      immediate: true,
    },
    dateOfBirth: {
      handler(value) {
        this.formUser.newUserRequestDto.dateOfBirth = moment(value, 'DD-MM-YYYY').format(
          'YYYY-MM-DD',
        );
      },
    },
    time1: {
      handler(value) {
        this.formUser.newOverviewRequestDto.startDate = moment(value, 'DD-MM-YYYY').format(
          'YYYY-MM-DD',
        );
      },
    },
    time2: {
      handler(value) {
        if (value != null) {
          this.formUser.newOverviewRequestDto.endDate = moment(value, 'DD-MM-YYYY').format(
            'YYYY-MM-DD',
          );
        }
        this.formUser.newOverviewRequestDto.endDate = moment(value, 'DD-MM-YYYY').format(
          'YYYY-MM-DD',
        );
      },
    },
    canton: {
      handler(value) {
        this.formUser.newAddressRequestDto.countryState = value.name;
      },
      immediate: true,
    },
  },
  created() {
    AWS.config.update({
      accessKeyId: AWSurl.accessKeyId,
      secretAccessKey: AWSurl.secretAccessKey,
      region: AWSurl.region,
    });

    this.s3 = new AWS.S3();
    this.loadCountryStates();
  },
  methods: {
    ...mapActions(['loadCountryStates']),
    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true;
        this.formUser.newUserRequestDto.phoneNumber = n.nationalNumber;
        this.formUser.newUserRequestDto.countryCode = n.countryCallingCode;
      } else {
        this.formUser.newUserRequestDto.countryCode = '';
        this.isPhoneNumberValid = false;
      }
      // console.log();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.formUser.newUserRequestDto[name];
      return $dirty ? !$error : null;
    },
    validateState2(name) {
      const { $dirty, $error } = this.$v.formUser.newAddressRequestDto[name];
      return $dirty ? !$error : null;
    },
    validateState3(name) {
      const { $dirty, $error } = this.$v.formUser.newOverviewRequestDto[name];
      return $dirty ? !$error : null;
    },
    onChangePassport(event) {
      this.$v.myFiles.$touch();
      this.fileDataPassport = [];
      const files = event.target.files;
      if (files.length <= 3) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileContent = new FileReader();
          fileContent.readAsArrayBuffer(file);

          const promise = new Promise((resolve) => {
            fileContent.onload = (e) => {
              const fileData = {
                fileContent: Buffer.from(e.target.result),
                filetype: file.type.split('/')[1],
                fileName: file.name.split('.')[0],
              };
              resolve(fileData);
            };
          });

          promise.then((fileData) => {
            this.fileDataPassport.push(fileData);
          });

          // console.log(this.fileDataPassport, 'fasfa');
        }
      } else {
        console.error('You can only select a maximum of 3 files.');
      }
    },
    onChangeAufenthaltsbewilligung(event) {
      this.$v.myFiles.$touch();
      this.fileDataAufenthaltsbewilligung = [];
      const files = event.target.files;
      if (files.length <= 3) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileContent = new FileReader();
          fileContent.readAsArrayBuffer(file);

          const promise = new Promise((resolve) => {
            fileContent.onload = (e) => {
              const fileData = {
                fileContent: Buffer.from(e.target.result),
                filetype: file.type.split('/')[1],
                fileName: file.name.split('.')[0],
              };
              resolve(fileData);
            };
          });

          promise.then((fileData) => {
            this.fileDataAufenthaltsbewilligung.push(fileData);
          });

          // console.log(this.fileData, 'fasfa');
        }
      } else {
        console.error('You can only select a maximum of 3 files.');
      }
    },
    onChangeAHV(event) {
      this.$v.myFiles.$touch();
      this.fileDataAHV = [];
      const files = event.target.files;
      if (files.length <= 3) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileContent = new FileReader();
          fileContent.readAsArrayBuffer(file);

          const promise = new Promise((resolve) => {
            fileContent.onload = (e) => {
              const fileData = {
                fileContent: Buffer.from(e.target.result),
                filetype: file.type.split('/')[1],
                fileName: file.name.split('.')[0],
              };
              resolve(fileData);
            };
          });

          promise.then((fileData) => {
            this.fileDataAHV.push(fileData);
          });

          // console.log(this.fileData, 'fasfa');
        }
      } else {
        console.error('You can only select a maximum of 3 files.');
      }
    },
    uploadFileToS3Passport(fileData) {
      return new Promise((resolve, reject) => {
        const params = {
          Bucket: this.bucketName,
          Key: `${Date.now()}-${fileData.fileName}.${fileData.filetype}`,
          Body: fileData.fileContent,
          ContentType: fileData.filetype,
        };
        const passportDocumentKey = params.Key;
        const passportDocObject = this.formUser.newDocumentRequestDto.find((doc) => {
          return doc.documentType === 'Passport';
        });
        if (passportDocObject) {
          passportDocObject.key.push(passportDocumentKey); // add the new passport document key to the key property of the Passport object
        } else {
          const newDocumentObject = {
          // create a new object with the new passport document key
            documentType: 'Passport',
            expiryDate: '2023-12-12',
            key: [passportDocumentKey],
          };
          this.formUser.newDocumentRequestDto.push(newDocumentObject); // push the new object to the array
        }
        this.s3.putObject(params, (error) => {
          if (error) {
            console.error(error);
            this.showFileError = true;
            reject(error);
          } else {
            resolve();
            console.log('File uploaded successfully to S3');
            this.showFileError = false;
          }
        });
      });
    },
    uploadFileToS3Aufenthaltsbewilligung(fileData) {
      return new Promise((resolve, reject) => {
        const params = {
          Bucket: this.bucketName,
          Key: `${Date.now()}-${fileData.fileName}.${fileData.filetype}`,
          Body: fileData.fileContent,
          ContentType: fileData.filetype,
        };
        const aufenthaltsbewilligungDocumentKey = params.Key;
        const aufenthaltsbewilligungDocObject = this.formUser.newDocumentRequestDto.find((doc) => {
          return doc.documentType === 'ResidencePermit';
        });
        if (aufenthaltsbewilligungDocObject) {
          aufenthaltsbewilligungDocObject.key.push(aufenthaltsbewilligungDocumentKey); // add the new passport document key to the key property of the Passport object
        } else {
          const newDocumentObject = {
          // create a new object with the new passport document key
            documentType: 'ResidencePermit',
            expiryDate: '2023-12-12',
            key: [aufenthaltsbewilligungDocumentKey],
          };
          this.formUser.newDocumentRequestDto.push(newDocumentObject); // push the new object to the array
        }
        this.s3.putObject(params, (error) => {
          if (error) {
            console.error(error);
            this.showFileError2 = true;
            reject(error);
          } else {
            console.log('File uploaded successfully to S3');
            this.showFileError2 = false;
            resolve();
          }
        });
      });
    },
    uploadFileToS3AHV(fileData) {
      return new Promise((resolve, reject) => {
        const params = {
          Bucket: this.bucketName,
          Key: `${Date.now()}-${fileData.fileName}.${fileData.filetype}`,
          Body: fileData.fileContent,
          ContentType: fileData.filetype,
        };
        const ahvDocumentKey = params.Key;
        const ahvDocObject = this.formUser.newDocumentRequestDto.find((doc) => {
          return doc.documentType === 'SocialNumber';
        });
        if (ahvDocObject) {
          ahvDocObject.key.push(ahvDocumentKey); // add the new passport document key to the key property of the Passport object
        } else {
          const newDocumentObject = {
          // create a new object with the new passport document key
            documentType: 'SocialNumber',
            expiryDate: '2023-12-12',
            key: [ahvDocumentKey],
          };
          this.formUser.newDocumentRequestDto.push(newDocumentObject); // push the new object to the array
        }
        this.s3.putObject(params, (error) => {
          if (error) {
            console.error(error);
            this.showFileError3 = true;
            reject(error);
          } else {
            resolve();
            this.showFileError3 = false;
            console.log('File uploaded successfully to S3');
          }
        });
      });
    },
    async onSubmitAddUser() {
      // this.formSubmitted = true;
      // this.$v.formUser.newUserRequestDto.$touch();
      // if (this.$v.formUser.newUserRequestDto.$anyError) {
      //   return;
      // }

      try {
        await this.$refs.addFile1.uploadFile();
        await this.$refs.addFile2.uploadFile();
        await this.$refs.addFile3.uploadFile();

        this.loading = true;
        this.loading2 = true;
        this.loading3 = true;

        this.$v.formUser.newOverviewRequestDto.$touch();
        if (this.$v.formUser.newOverviewRequestDto.$anyError) {
          return;
        }

        this.showFileError = false;
        this.showFileErro2 = false;
        this.showFileErro3 = false;


        this.$emit('pushFormUser', CircularJSON.stringify(this.formUser));
        this.$refs.modal.hide();
        setTimeout(() => { this.$v.$reset() }, 0)
        this.$nextTick(() => {
          this.$v.$reset();
        });
        this.onReset();
      } catch (error) {
        console.error('An error occurred during file upload:', error);
      } finally {
        this.loading = false; // Disable the button
        this.loading2 = false; // Disable the button
        this.loading3 = false; // Disable the button
      }
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    onReset() {
      // Reset our form values
      this.formUser.newUserRequestDto.firstName = '';
      this.formUser.newUserRequestDto.lastName = '';
      this.formUser.newUserRequestDto.email = '';
      this.formUser.newUserRequestDto.dateOfBirth = '';
      this.dateOfBirth = '';
      this.formUser.newUserRequestDto.salutation = '';
      this.formUser.newUserRequestDto.maritalStatus = '';
      this.formUser.newUserRequestDto.nationality = '';
      this.formUser.newUserRequestDto.residencePermit = '';
      this.formUser.newUserRequestDto.phoneNumber = '';
      this.formUser.newUserRequestDto.countryCode = '';
      this.formUser.newUserRequestDto.personRole = '';
      this.formUser.newUserRequestDto.employeeType = '';
      this.formUser.newUserRequestDto.numberOfVacationDays = 0;
      this.formUser.newAddressRequestDto.street = '';
      this.formUser.newAddressRequestDto.houseNumber = '';
      this.formUser.newAddressRequestDto.doorNumber = '';
      this.formUser.newAddressRequestDto.postalCode = '';
      this.formUser.newAddressRequestDto.city = '';
      this.formUser.newAddressRequestDto.country = '';
      this.formUser.newAddressRequestDto.countryState = '';
      this.formUser.newOverviewRequestDto.startDate = '';
      this.time1 = '';
      this.showFileError = false;
      this.showFileError2 = false;
      this.showFileError3 = false;
      this.formUser.newOverviewRequestDto.endDate = '2099-01-01';
      this.formUser.newOverviewRequestDto.workload = 0;
      this.formUser.newOverviewRequestDto.salary = 0;
      this.formUser.newOverviewRequestDto.childAllowances = 0;
      this.formUser.newOverviewRequestDto.salaryChanges = 0;
      this.formUser.newUserRequestDto.socialNumber = '';
      this.canton = '';
      this.fileDataAHV = [];
      this.fileDataAufenthaltsbewilligung = [];
      this.fileDataPassport = [];
      this.formUser.newDocumentRequestDto = [];
      // Trick to reset/clear native browser form validation state
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  align-items: flex-end;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 50%
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
.country-phone-input .dropdown:focus {
  outline: none;
  color: grey;
}
</style>
