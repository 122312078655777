<template>
  <div>
    <b-modal
      id="user-deactivate"
      ref="modal"
      size="l"
      header-class="headerModal"
      centered
      :title="`${$t('AddNew6')} ${editId.firstName}`"
      hide-footer
      @shown="handleModalShown"
      @close="onCancel"
    >
      <div
        v-for="item in getPlannedDaysByEmployee"
        :key="item.tourTruckId"
      >
        <!-- <p v-if="getPlannedDaysByEmployee.length != 0">
        Active Routes of <b>{{ editId.firstName }}</b> employee
      </p> -->
        <p style="margin-top: 20px;">
          There is a scheduled route for this employee between the dates of <b style="color: black;">{{ new Date(item.plannedDate).toLocaleDateString('en-GB') }} - {{ new Date(item.plannedDate).toLocaleDateString('en-GB') }}</b>
        </p>
        <div
          style="
          background-color: gainsboro;
          border-radius: 8px;
          margin-top: 10px;
          max-width: 100%;
          padding: 10px;
          min-height: 56px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
        "
        >
          <div
            style="
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-bottom: 10px;"
          >
            <b-form-group
              id="input-group-8"
              label="Date"
              label-for="input-25"
              style="margin-right: 5px; text-align: left;"
            >
              {{ new Date(item.plannedDate).toLocaleDateString('en-GB') }}
            </b-form-group>
            <b-form-group
              id="input-group-8"
              label="Is in Route"
              label-for="input-25"
              style="text-align: left;"
            >
              {{ item.truckStaffId.length !== 0 ? $t('Yes') : $t('No') }}

            </b-form-group>
            <div style="display: none;">
              <label for="start-shift-input">Start Shift:</label>
              <input
                id="start-shift-input"
                v-model="item.startShift"
                type="text"
              >
            </div>
            <div style="display: flex; flex-direction: column;">
              <b-button
                type="button"
                variant="none"
                style="margin-bottom: 5px"
                class="buttonSubmit"
                @click="replaceShow(item)"
              >
                Replace
              </b-button>
              <b-button
                v-if="item.employeeCounter != 1"
                type="button"
                variant="none"
                style=""
                class="buttonSubmit"
                @click="handleReleaseClick(item), selectedItemId = item.tourTruckId"
              >
                Remove
              </b-button>
            </div>
          </div>
          <b-form-group
            v-if="replace == true && item.tourTruckId === selectedItemId"
            id="input-group-8"
            style="width: 100%; padding-top: 20px;"
            label="Select Free Employee"
            label-for="input-25"
          >
            <vue-select
              id="input-8"
              v-model="substituteEmployee"
              :options="getFreeEmployeesForManagerByStoreId"
              label="userFullName"
            />
          </b-form-group>
          <b-button
            v-if="replace == true && item.tourTruckId === selectedItemId"
            type="button"
            variant="none"
            style="margin-left: -343px; margin-top: 10px; width: 100px;"
            class="buttonSubmit"
            @click="replaceEmployee(item)"
          >
            Save
          </b-button>
        </div>
      </div>
      <b-form>
        <b-form-group
          v-if="editId.role =='TeamLeader'"
          id="input-group-8"
          label="Select Co-TeamLeader to replace"
          label-for="input-25"
          style="margin-top: 10px"
        >
          <vue-select
            id="input-8"
            v-model="substituteTeamLeader"
            :options="getfreeCoTeamLeaders"
            label="userFullName"
          />
        </b-form-group>
        <b-form-group
          v-if="getPlannedDaysByEmployee.length == 0"
          id="input-group-8"
          :label="$t('EmployeeSelectReason')"
          label-for="input-25"
          style="margin-top: 10px"
        >
          <vue-select
            id="input-8"
            v-model="reason"
            :placeholder="$t('Select')"
            :options="[
              this.$t('EmployeePoorPerformance'),
              this.$t('EmployeeAttendance'),
              this.$t('EmployeeViolation'),
              this.$t('EmployeePersonalReasons'),
              this.$t('EmployeeOther'),
            ]"
          />
        </b-form-group>
        <b-form-group
          v-if="showInput"
          id="input-group-8"
          label="Other reason"
          label-for="input-25"
        >
          <b-form-input
            id="input-8"
            v-model="form.message"
            style="height: 80px"
          />
        </b-form-group>
      </b-form>
      <div style="margin-top: 20px">
        <b-button
          type="button"
          variant="none"
          style="margin-left: 0px"
          class="buttonSubmit"
          :disabled="getPlannedDaysByEmployee.length != 0"
          @click="onSubmit"
        >
          {{ $t("Deactivate") }}
        </b-button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          @click="onCancel"
        >
          {{ $t("ExchangeCancel") }}
        </b-button>
      </div></b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    editId: {
      type: Object,
    },
    editToggle: {
      type: Boolean,
    },
  },
  data() {
    return {
      substituteEmployee: null,
      substituteTeamLeader: null,
      selectedItemId: null,
      datee: null,
      replace: false,
      filters: {
        page: 1,
        pageSize: 20,
        query: '',
        ...this.$route.query,
      },
      totalItems: '',
      options: [],
      selected: null,
      button: false,
      test: this.editToggle,
      form: {
        userId: null,
        message: '',
        exitDate: moment().format('DD-MM-YYYY'),
      },
      teamLeaderForm: {
        userId: null,
        coTeamLeaderId: null,
        message: '',
        exitDate: moment().format('DD-MM-YYYY'),
      },
      reason: '',
      coTeamLeader: '',
      showInput: false,
      replaceObject: {
        tourTruckId: '',
        employeeId: '',
        substituteEmployee: '',
        message: '',
      },
    };
  },
  computed: {
    ...mapGetters(['getPlannedDaysByEmployee', 'getFreeEmployeesForManagerByStoreId', 'getLoggedInUser', 'getfreeCoTeamLeaders']),
  },
  watch: {
    editId(newValue) {
      this.form.userId = newValue.userId;
    },
    reason(value) {
      if (value == 'Other') {
        this.showInput = true;
        this.form.message = '';
      } else {
        this.showInput = false;
        this.form.message = value;
      }
    },
    'form.userId': {
      handler(value) {
        this.teamLeaderForm.userId = value;
      },
      immediate: true,
    },
    'form.message': {
      handler(value) {
        this.teamLeaderForm.message = value;
      },
      immediate: true,
    },
    'form.exitDate': {
      handler(value) {
        this.teamLeaderForm.exitDate = value;
      },
      immediate: true,
    },
    'substituteTeamLeader.userId': {
      handler(value) {
        this.teamLeaderForm.coTeamLeaderId = value;
      },
      immediate: true,
    },
  },
  mounted() {
    const res = moment.tz.guess();
    const value2 = moment.utc(new Date());
    this.datee = moment.tz(value2, res).format('YYYY-MM-DDTHH:mm:ss.SSSZ'); // Note the [Z] added to format

    // this.freeEmployee({ storeId: null, startShift: '2023-02-14', endShift: '2023-02-14' });
  },
  methods: {
    ...mapActions(['releaseAndReplaceEmployeeFromTour', 'freeEmployee', 'loadPlannedDaysByEmployee', 'editEmployeePlanning', 'deleteEmployeePlanning',
      'releaseEmployeeFromTour', 'getTourNameByEmployeeUserIdStartAndEndShift', 'freeCoTeamLeaders', 'freeEmployeesForManagerByStoreId']),

    async replaceEmployee(value) {
      if (value.truckStaffId.length !== 0) {
        await this.releaseAndReplaceEmployeeFromTour(
          {
            obj: {
              truckStaffId: value.truckStaffId[0],
              // tourTruckId: this.selectedItemId,
              storeId: null,
              substituteEmployee: this.substituteEmployee.userId,
              employeePlanId: value.employeePlanningId,
              message: '',
              dateReleased: moment.utc().tz(moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            },
            successCallback: () => {
              // this.getTourNameByEmployeeUserIdStartAndEndShift({ employeeUserId: this.form.userId, startShift: moment.tz().set({ hour: 8, minute: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSSZ'), endShift: null });
              this.loadPlannedDaysByEmployee({
                employeeUserId: this.form.userId, startDate: moment().format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD'),
              });
              this.substituteEmployee = null;
              this.substituteTeamLeader = null;
            },
          },
        );
      } else if (value.truckStaffId.length == 0) {
        this.editEmployeePlanning({
          obj: {
            employeePlanId: value.employeePlanningId,
            newEmployeeId: this.substituteEmployee.userId,
          },
          successCallback: () => {
            // const startDate = moment(this.time1, 'DD-MM-YYYY').format('YYYY-MM-DD')
            // const endDate = moment(this.time2, 'DD-MM-YYYY').format('YYYY-MM-DD')
            this.loadPlannedDaysByEmployee({
              employeeUserId: this.form.userId, startDate: moment.tz().set({ hour: 8, minute: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSSZ'), endDate: moment.tz().set({ hour: 17, minute: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            });
            this.substituteEmployee = null;
            this.substituteTeamLeader = null;
          },
        })
      }
    },
    handleModalShown() {
      // Call your method here
      // this.freeCoTeamLeaders();
    },
    handleReleaseClick(test) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to release this employee?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Release',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then((value) => {
          if (value) {
            this.releaseEmployee(test);
          }
        })
        .catch((err) => {
          console.log('Error in msgBoxConfirm:', err);
        });
    },
    async releaseEmployee(value) {
      // await this.releaseEmployeeFromTour({
      //   obj: {
      //     truckStaffId: value,
      //     message: 'string',
      //     dateReleased: moment.utc().tz(moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      //   },
      //   successCallback: () => {
      //     this.getTourNameByEmployeeUserIdStartAndEndShift({ employeeUserId: this.form.userId, startShift: new Date().toJSON().slice(0, 10).replace(/-/g, '-'), endShift: null });
      //   },
      // });
      if (value.truckStaffId.length !== 0) {
        await this.releaseEmployeeFromTour({
          obj: {
            truckStaffId: value.truckStaffId[0],
            message: '',
            dateReleased: moment().tz(moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          },
          successCallback: () => {
            this.loadPlannedDaysByEmployee({ employeeUserId: this.form.userId, startDate: moment.tz().set({ hour: 8, minute: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSSZ'), endDate: moment.tz().set({ hour: 17, minute: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSSZ') });
          },
        });
      } else if (value.truckStaffId.length === 0) {
        this.deleteEmployeePlanning({
          employeePlanId: value.employeePlanningId,
          successCallback: () => {
            this.loadPlannedDaysByEmployee({ employeeUserId: this.form.userId, startDate: moment.tz().set({ hour: 8, minute: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSSZ'), endDate: moment.tz().set({ hour: 17, minute: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSSZ') });
          },
        })
      }
    },
    replaceShow(value) {
      // console.log('valuee', value)
      this.replace = !this.replace;
      this.selectedItemId = value.tourTruckId
      // const startDate = this.getPlannedDaysByEmployee.find((item) => { return item.tourTruckId === value.tourTruckId }).startDate;
      // const endDate = this.getPlannedDaysByEmployee.find((item) => { return item.tourTruckId === value.tourTruckId }).endDate;

      // this.freeEmployee({
      //   storeId: value.storeId, startDate, endDate, includeTeamLeaders: true, includeManager: false,
      // });
      this.freeEmployeesForManagerByStoreId({
        storeId: value.storeId, startShift: moment.tz().set({ hour: 8, minute: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSSZ'), endShift: moment.tz().set({ hour: 17, minute: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      });
    },
    onSubmit() {
      if (this.editId.role == 'TeamLeader') { this.$emit('objData', this.teamLeaderForm); } else { this.$emit('objData', this.form); }
      this.$refs.modal.hide();
      this.onReset();
    },
    onCancel() {
      this.$refs.modal.hide();
      this.$emit('toggle', this.editToggle);
      this.onReset();
    },
    onReset() {
      this.form.message = '';
      this.reason = null;
      this.substituteEmployee = null;
      this.substituteTeamLeader = null;
      this.selectedItemId = null;
      this.replace = false;
      this.selected = null;
      this.button = false;
      this.form.userId = null;
      this.form.message = '';
      this.form.exitDate = moment().format('DD-MM-YYYY');
      this.teamLeaderForm.userId = null;
      this.teamLeaderForm.coTeamLeaderId = null;
      this.teamLeaderForm.message = '';
      this.teamLeaderForm.exitDate = moment().format('DD-MM-YYYY');
      this.reason = '';
      this.coTeamLeader = '';
      this.replaceObject.tourTruckId = '';
      this.replaceObject.employeeId = '';
      this.replaceObject.substituteEmployee = '';
      this.replaceObject.message = '';
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.deactivate-modal__select {
  display: flex;
  flex-direction: column;

  select {
    margin-bottom: 10px;
    padding: 10px;
  }

  button {
    align-self: end;
  }
}

.flex-form {
  display: flex;
  flex-direction: column;
}

.my-class {
  background: black;
  font-weight: bold;
}

// .my-second-class > .modal-dialog > .modal-content > .modal-header {
// background: black;
// color: white;
// }
</style>
