<template>
  <div
    style="margin-top: 0px"
    @click="removeLists"
  >
    <div>
      <p style="font-size: 32px; font-weight: bold; border-bottom: 1px solid lightgray;">
        {{ $t("EmployeeManagement") }}
      </p>
      <div
        class="table__header"
        :style="
          isactive == true
            ? 'justify-content: space-between; margin-bottom: 9.5px;'
            : 'justify-content: flex-end; margin-bottom: 9.5px;'
        "
      >
        <button
          v-show="isactive == true"
          v-b-modal.add-user
          class="addEmployeeButton button"
          style="border-radius: 6px; width:220px"
        >
          <b-icon-plus />
          {{ $t("EmployeeAddEmployee") }}
        </button>
        <div
          style="display: flex"
          class="con"
        >
          <div
            style="margin-right: 15px"
            class="active-inactive-con"
          >
            <button
              class="button"
              style="margin-right: 15px; border-radius: 6px; width:220px"
              :disabled="isactive == true"
              @click="activeF"
            >
              {{ $t("EmployeeActiveUsers") }}
            </button>
            <button
              class="button"
              :disabled="isactive == false"
              style="border-radius: 6px; width:220px"
              @click="activeF"
            >
              {{ $t("EmployeeInactiveUsers") }}
            </button>
          </div>
          <div>
            <div class="black">
              <b-icon-search
                v-if="searchUser == ''"
                class="search1"
                style="position: absolute; left: 5px; top: 12px"
              />
              <b-icon-x-circle
                v-else-if="searchUser != '' && !noSearch"
                class="search1"
                style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                @click="searchUser = ''"
              />
              <input
                v-model="searchUser"
                autocomplete="off"
                type="text"
                name="search-box"
                :placeholder="$t('EmployeeFullName')"
                :style="
                  getFilteredActiveUsers.length > 0 ||
                    (getFilteredInactiveUsers.length > 0 && noSearch)
                    ? 'border-radius: 8px 8px 0px 0px'
                    : ''
                "
                @keyup="inputChanged"
                @keydown.down="onArrowDown"
                @keydown.up="onArrowUp"
              >
            </div>
            <div
              v-if="
                (getFilteredActiveUsers.length > 0 || getFilteredInactiveUsers.length > 0) &&
                  noSearch
              "
              ref="scrollContainer"
              class="dropdrop"
            >
              <div
                v-for="(result, index) in getFilteredActiveUsers.length > 0
                  ? getFilteredActiveUsers
                  : getFilteredInactiveUsers"
                :key="index"
                ref="options"
                style="cursor: pointer"
                class="search-item"
                :class="{ 'is-active': index === arrowCounter }"
                @click="
                  searchByOn(result);
                  noSearch = false;
                "
              >
                <p style="margin: 0">
                  {{ result.fullName }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="width: 100%; padding-top: 16px"
        class="scroll"
      >
        <table
          class="team_table"
          style="width: 100%; box-shadow: 0px 7px 20px rgb(40 41 61 / 8%); font-size: 12px"
        >
          <thead>
            <tr>
              <th style="padding: 10px; border-radius: 10px 0px 0px 0px; text-align: left;">
                {{ $t("No.") }}
              </th>
              <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: left;">
                {{ $t("EmployeeFirstName") }}
              </th>
              <th style="padding: 10px; text-align: left;">
                {{ $t("EmployeeLastName") }}
              </th>
              <th style="padding: 10px; text-align: left;">
                {{ $t("EmployeeCreatedDate") }}
              </th>
              <th style="padding: 10px; text-align: left;">
                {{ $t("EmployeeEmployeeT") }}
              </th>
              <th
                v-if="isactive == true"
                style="padding: 10px; text-align: left; cursor: pointer"
                @click="sortByRole"
              >
                {{ $t("EmployeeRole") }}
                <b-icon-sort-alpha-up-alt v-if="sortedR == false" />
                <b-icon-sort-alpha-down v-else />
              </th>
              <th
                v-else
                style="padding: 10px; text-align: left; cursor: pointer"
                @click="sortByRoleI"
              >
                {{ $t("EmployeeRole") }}
                <b-icon-sort-alpha-down v-if="sortedRI == false" />
                <b-icon-sort-alpha-up-alt v-else />
              </th>
              <th
                v-if="isactive == true"
                style="padding: 10px; text-align: left; cursor: pointer"
              >
                {{ $t("EmployeeAssignF") }}
              </th>
              <th
                colspan="2"
                style="padding: 10px; border-radius: 0px 10px 0px 0px; text-align: left;"
              >
                {{ $t("EmployeeActions") }}
              </th>
            </tr>
          </thead>

          <tbody v-if="isactive == true">
            <tr
              v-for="(user, index) in getUsers"
              :key="index"
              v-b-modal.view-user
              v-b-tooltip.hover.bottom
              style="
                border-bottom: 1px solid #dcdfe6;
                color: #606266;
                text-align: left;
                cursor: pointer;
              "
              :title="`Click for ${user.firstName} ${user.lastName} details`"
              @click="matProps(user), showSameId(user.userId), viewItem(user.userId)"
            >
              <td style="padding: 9px">
                {{ getTotalItemsForUsers - (page - 1) * pageSize - index }}.
              </td>
              <td style="padding: 9px">
                {{ user.firstName }}
              </td>
              <td style="padding: 9px">
                {{ user.lastName }}
              </td>
              <td style="padding: 9px">
                {{ dateModified(user.created) }}
              </td>
              <td
                v-if="user.employeeType === 'Internal'"
                style="padding: 9px"
              >
                {{ $t("Internal") }}
              </td>
              <td
                v-else
                style="padding: 9px"
              >
                {{ $t("External") }}
              </td>
              <td style="padding: 9px">
                {{ user.personRole }}
              </td>
              <td style="padding: 4px">
                {{ user.storeName }}
                <b-button
                  v-if="(user.personRole == 'Employee' || user.personRole == 'TeamLeader' || user.personRole == 'CoTeamLeader' || user.personRole == 'Manager') && user.storeName == null"
                  v-b-modal.assign-employee-to-filiale
                  style="max-height: 35px; font-size: small; background: transparent linear-gradient(53deg, #0071AE 0%, #55B94A 100%) 0% 0% no-repeat padding-box;"
                  @click.stop="saveEmployeeId(user)"
                >
                  {{ $t("Assign") }}
                </b-button>
              </td>
              <td
                style="padding: 9px; text-align: left; cursor: pointer"
                @click.stop="editItem(user.userId)"
              >
                <p
                  v-b-toggle.edit-user-toggle
                  :disabled="getLoggedInUser.personRole == 'TeamLeader'"
                  class="p-0 m-0 ml-50"
                >
                  <b-icon-pencil />
                </p>
              </td>
              <td
                @click.stop="deactivate(user), loadActiveRoutesByUSERID(user.userId)"
              >
                <p
                  v-b-modal.user-deactivate
                  class="p-0 m-0 ml-50"
                  style="text-align: left;"
                >
                  <toggle-button
                    :value="true"
                    :sync="true"
                    color="#0071AE"
                  />
                </p>
              </td>
            </tr>
            <div style="margin: 10px">
              <!-- <b-button
                v-if="selectedId != null"
                @click="showAll"
              >
                View All
              </b-button> -->
            </div>
          </tbody>
          <tbody v-else-if="isactive == false">
            <tr
              v-for="(user,index) in getInactiveUsers"
              :key="user.userId"
              style="
                border-bottom: 1px solid #dcdfe6;
                color: #606266;
                text-align: left;
                cursor: pointer;
              "
            >
              <td style="padding: 9px">
                {{ getTotalItemsForInActiveUsers - (page - 1) * pageSize - index }}.
              </td>
              <td style="padding: 9px">
                {{ user.firstName }}
              </td>
              <td style="padding: 9px">
                {{ user.lastName }}
              </td>
              <td style="padding: 9px">
                {{ dateModified(user.created) }}
              </td>
              <td style="padding: 9px">
                {{ user.employeeType }}
              </td>
              <td style="padding: 9px">
                {{ user.personRole }}
              </td>

              <td
                style="padding: 9px; text-align: left; cursor: pointer"
                @click="editItem(user.userId)"
              >
                <p
                  v-b-modal.edit-user
                  :disabled="
                    (getLoggedInUser.role == 'Manager' &&
                      (user.personRole == 'TeamLeader' ||
                        user.personRole == 'CEO' ||
                        user.personRole == 'Manager')) ||
                      (getLoggedInUser.personRole == 'CEO' &&
                        (user.personRole == 'TeamLeader' || user.personRole == 'CEO'))
                  "
                  class="p-0 m-0 ml-50"
                >
                  <b-icon-pencil />
                </p>
              </td>
              <td @click="activate(user)">
                <p
                  v-b-modal.user-activate
                  :disabled="
                    (getLoggedInUser.role == 'Manager' &&
                      (user.personRole == 'TeamLeader' ||
                        user.personRole == 'CEO' ||
                        user.personRole == 'Manager')) ||
                      (getLoggedInUser.personRole == 'CEO' &&
                        (user.personRole == 'TeamLeader' || user.personRole == 'CEO'))
                  "
                  class="p-0 m-0 ml-50"
                  style="text-align: left;"
                >
                  <toggle-button
                    :value="user.isActive"
                    :sync="true"
                  />
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <b-pagination
          v-if="isactive == true"
          v-model="page"
          :total-rows="getTotalItemsForUsers"
          :per-page="pageSize"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span>{{ $t("Previous") }}</span>
          </template>
          <template #next-text>
            <span>{{ $t("Next") }}</span>
          </template>
        </b-pagination>
        <b-pagination
          v-else
          v-model="page"
          :total-rows="getTotalItemsForInActiveUsers"
          :per-page="pageSize"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span>{{ $t("Previous") }}</span>
          </template>
          <template #next-text>
            <span>{{ $t("Next") }}</span>
          </template>
        </b-pagination>
      </div>
    </div>
    <AddUserModal @pushFormUser="addUsers" />
    <EditUserModal
      :edit-user="editUser"
      @pushFormUser="editUserbyIdd"
      @getPhotos="getAllImagesEdit"
    />
    <ViewUserDetailsModal
      :edit-user="editUser"
      @pushFormUser="editUserbyIdd"
      @getPhotos="getAllImagesEdit"
    />
    <DeactivateUserModal
      :edit-id="deactivateItemId"
      @toggle="toggleBtn"
      @objData="deactivateId"
    />
    <ActivateUserModal
      :edit-id="activateItemId"
      @objData="activateId"
    />
    <AssignUserToFiliale
      :employee-id="employeeId"
      @assignToFiliale="assignEmployeeToFiliale"
    />
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { mapActions, mapGetters } from 'vuex';
import AddUserModal from '@/components/employees/modals/AddUserModal.vue';
import EditUserModal from '@/components/employees/modals/EditUserModal.vue';
import ViewUserDetailsModal from '@/components/employees/modals/ViewUserDetailsModal.vue';
import DeactivateUserModal from '@/components/employees/modals/DeactivateUserModal.vue';
import ActivateUserModal from '@/components/employees/modals/ActivateUserModal.vue';
import AssignUserToFiliale from '@/components/employees/modals/AssignUserToFiliale.vue';

export default {
  components: {
    AddUserModal,
    EditUserModal,
    ViewUserDetailsModal,
    DeactivateUserModal,
    ActivateUserModal,
    AssignUserToFiliale,
  },
  props: {
    users: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showOtherComponent: false,
      isactive: true,
      searchUser: '',
      noSearch: false,
      sortedR: false,
      sortedRI: false,
      selectedId: null,
      editUser: {
        userId: '',
        firstName: '',
        lastName: '',
        salutation: '',
        gender: '',
        city: '',
        dateOfBirth: '',
        phoneNumber: '',
        postalCode: '',
        doorNumber: '',
        street: '',
        country: '',
        email: '',
        role: '',
        resetPassword: false,
      },
      deactivateItemId: {},
      activateItemId: {},
      totalItems: '',
      page: 1,
      pageSize: 15,
      status: true,
      arrowCounter: 0,
      enterPressed: false,
      employeeId: null,
    };
  },
  computed: {
    ...mapGetters([
      'getUsers',
      'getLoggedInUser',
      'getInactiveUsers',
      'getFilteredActiveUsers',
      'getFilteredInactiveUsers',
      'getTotalItemsForUsers',
      'getTotalItemsForInActiveUsers',
    ]),
    // filteredUsers() {
    //   // filter the users array to only show rows with the same id as the selected id
    //   return this.selectedId
    //     ? this.getUsers.filter((getUsers) => {
    //       return getUsers.userId === this.selectedId;
    //     })
    //     : this.getUsers;
    // },
  },
  watch: {
    page(value) {
      if (this.isactive) {
        this.getUsersPagination({
          status: true,
          pageNumber: value,
          pageSize: this.pageSize,
          fullName: this.searchUser,
        });
      }
    },
    searchUser(value) {
      if (value == '') {
        this.removeLists();
        if (this.isactive) {
          this.getUsersPagination({
            status: true,
            pageNumber: this.page,
            pageSize: this.pageSize,
            fullName: value,
          });
        } else if (this.enterPressed) {
          // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.getInactiveUsersPagination({
            status: false,
            pageNumber: this.page,
            pageSize: this.pageSize,
            fullName: value,
          });
        }
      } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
        return;
      } else if (this.isactive) {
        this.filterActiveUsersByName(value);
      } else {
        this.filterInactiveUsersByName(value);
      }
      this.noSearch = true;
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions([
      'getTourNameByEmployeeUserIdStartAndEndShift',
      'getUsersPagination',
      'getInactiveUsersPagination',
      'addUser',
      'deactivateUserById',
      'filterActiveUsersByName',
      'resetFilteredActiveUsers',
      'editUserById',
      'activateUserById',
      'filterInactiveUsersByName',
      'resetFilteredInactiveUsers',
      'changeLoadingtoTrue',
      'assignEmployeeToStoree',
      'getUserInformationById',
      'loadPlannedDaysByEmployee',
    ]),
    async fetch() {
      try {
        // this.changeLoadingtoTrue(false);

        await this.getUsersPagination({
          status: true,
          pageNumber: this.page,
          pageSize: this.pageSize,
          fullName: this.searchUser,
        }).then(() => {
          // this.changeLoadingtoTrue(true);
        });
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false);
      }
    },
    matProps(users) {
      this.$emit('click', users);
      this.classNameCh = users.id;
      this.$emit('show-the-component');
    },
    saveEmployeeId(id) {
      this.employeeId = id
    },
    async assignEmployeeToFiliale(obj) {
      await this.assignEmployeeToStoree({
        object: obj,
        successCallback: () => {
          this.getUsersPagination({
            status: this.status,
            pageNumber: this.page,
            pageSize: this.pageSize,
          });
        },
      });
    },
    showSameId(id) {
      // set the selected id to the id of the clicked row
      this.selectedId = id;
    },
    loadActiveRoutesByUSERID(id) {
      // this.getTourNameByEmployeeUserIdStartAndEndShift({ employeeUserId: id, startShift: new Date().toJSON().slice(0, 10).replace(/-/g, '-'), endShift: null });
      this.loadPlannedDaysByEmployee({ employeeUserId: id, startDate: new Date().toJSON().slice(0, 10).replace(/-/g, '-'), endDate: new Date().toJSON().slice(0, 10).replace(/-/g, '-') });
    },
    // showAll() {
    //   // set the selected id to null to show all rows
    //   this.selectedId = null;
    //   this.$emit('show-other-component');
    // },
    toggleBtn(truee) {
      this.sync = truee;
    },
    dateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY');
    },
    async addUsers(obj) {
      await this.addUser({
        object: obj,
        successCallback: () => {
          this.getUsersPagination({
            status: this.status,
            pageNumber: 1,
            pageSize: this.pageSize,
          });
        },
      });
    },
    onArrowDown(ev) {
      ev.preventDefault();
      if (this.arrowCounter < this.getFilteredActiveUsers.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault();
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling();
      }
    },

    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') {
        return;
      }
      // this.getFilteredActiveUsers = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true;
        this.searchByOn(this.getFilteredActiveUsers[this.arrowCounter]);
        this.removeLists();
        this.arrowCounter = 0;
        setTimeout(() => {
          this.enterPressed = false;
        }, 1000);
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH);
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    activeF() {
      this.isactive = !this.isactive;
      this.searchUser = '';
      if (this.isactive) {
        this.getUsersPagination({
          status: true,
          pageNumber: this.page,
          pageSize: this.pageSize,
          fullName: this.searchUser,
        });
      } else {
        this.getInactiveUsersPagination({
          status: false,
          pageNumber: this.page,
          pageSize: this.pageSize,
          fullName: this.searchUser,
        });
      }
    },
    async deactivateId(value) {
      await this.deactivateUserById({
        object: value,
        successCallback: () => {
          this.getUsersPagination({
            status: true,
            pageNumber: 1,
            pageSize: this.pageSize,
          });
          this.getInactiveUsersPagination({
            status: false,
            pageNumber: 1,
            pageSize: this.pageSize,
          });
        },
      });
    },
    activate(obj) {
      this.activateItemId = obj;
    },
    async activateId(id) {
      await this.activateUserById({
        id,
        successCallback: () => {
          this.getUsersPagination({
            status: true,
            pageNumber: 1,
            pageSize: this.pageSize,
          });
          this.getInactiveUsersPagination({
            status: false,
            pageNumber: 1,
            pageSize: this.pageSize,
          });
        },
      });
    },
    editItem(item) {
      this.getUserInformationById(item);
    },
    viewItem(item) {
      this.getUserInformationById(item);
    },
    getAllImagesEdit(item) {
      this.getUserInformationById(item);
    },
    async editUserbyIdd(obj) {
      await this.editUserById({
        object: obj,
        successCallback: () => {
          this.getUsersPagination({
            status: this.status,
            pageNumber: this.page,
            pageSize: this.pageSize,
          });
        },
      });
    },
    deactivate(obj) {
      this.deactivateItemId = obj;
    },
    sortByRole() {
      if (this.sortedR == false) {
        this.getUsers.sort((a, b) => {
          return a.role.localeCompare(b.role);
        });
        this.sortedR = true;
      } else {
        this.getUsers.sort((a, b) => {
          return a.role > b.role ? -1 : 1;
        });
        this.sortedR = false;
      }
    },
    sortByRoleI() {
      if (this.sortedRI == false) {
        this.getInactiveUsers.sort((a, b) => {
          return a.role.localeCompare(b.role);
        });
        this.sortedRI = true;
      } else {
        this.getInactiveUsers.sort((a, b) => {
          return a.role > b.role ? -1 : 1;
        });
        this.sortedRI = false;
      }
    },
    async searchByOn(value) {
      this.searchUser = value.fullName;
      this.noSearch = false;
      if (this.isactive) {
        await this.getUsersPagination({
          status: true,
          pageNumber: this.page,
          pageSize: this.pageSize,
          firstName: value.firstName,
          lastName: value.lastName,
        });
      } else {
        await this.getInactiveUsersPagination({
          status: false,
          pageNumber: this.page,
          pageSize: this.pageSize,
          firstName: value.firstName,
          lastName: value.lastName,
        });
      }
    },
    removeLists() {
      this.noSearch = false;
      this.resetFilteredActiveUsers();
      this.resetFilteredInactiveUsers();
    },
  },
};
</script>

<style scoped>
.button {
  width: 195px;
}
.addEmployeeButton {
  width: 210px;
}

span{
  color: black;
}
p{
  color: black;
}
table td{
  color: black;
}

@media screen and (max-width: 1600px) {
  .scroll {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 1110px) {
  .button {
    margin-right: 10px;
    font-size: 12px;
    height: 36px;
    width: 150px;
  }
}

.dropdrop::-webkit-scrollbar {
  display: none;
}

.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: rgb(40 41 61 / 14%) -1px 4px 10px;
  z-index: 3;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}

.black {
  position: relative;
}

.black input {
  border: none;
  box-shadow: rgb(40 41 61 / 14%) -1px 4px 10px;
}

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 600;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

@media screen and (max-width: 763px) {
  .con {
    flex-wrap: wrap;
  }

  .active-inactive-con {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .button {
    height: auto;
  }
}

.is-active {
  background-color: #dedede;
}
</style>
