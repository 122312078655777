<template>
  <div>
    <b-modal
      id="assign-employee-to-filiale"
      ref="modal"
      no-close-on-backdrop
      header-class="headerModal"
      centered
      :title="$t('AddNew4')"
      size="l"
      hide-footer
      @close="onCancel"
      @shown="handleModalShown"
    >
      <b-form>
        <b-form-group
          id="input-group-2"
          :label="$t('Filiale')"
          label-for="input-2"
        >
          <vue-select
            v-model="store"
            required
            :options="getFiliales"
            label="storeName"
            :value="storeId"
            :placeholder="$t('Select')"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>
        <pre />
        <div style="display: flex; width: 100%;">
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            :disabled="form.storeId==null"
            @click="assignEmployeeToFiliale"
          >
            {{ $t("Submit") }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px"
            @click="onCancel"
          >
            {{ $t("Cancel") }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {},
  // eslint-disable-next-line vue/require-prop-types
  props: ['employeeId'],
  data() {
    return {
      store: null,
      form: {
        storeId: null,
        employeeUserId: '',
        isTeamLeader: false,
        isCoTeamLeader: false,
        isManager: false,
      },
      storeId: 12345,
    };
  },
  computed: {
    ...mapGetters(['getFiliales']),
  },

  watch: {
    'store.storeId': {
      handler(value) {
        this.form.storeId = value;
      },
      immediate: true,
    },
    employeeId: {
      handler(value) {
        this.form.employeeUserId = value.userId;
        if (value.personRole === 'TeamLeader') {
          this.form.isTeamLeader = true;
        }
        if (value.personRole === 'CoTeamLeader') {
          this.form.isCoTeamLeader = true;
        }
        if (value.personRole === 'Manager') {
          this.form.isManager = true;
        }
      },
    },
  },
  mounted() {
    this.employeeUserId = this.employeeId;
  },
  methods: {
    ...mapActions(['loadFilialePagination']),
    assignEmployeeToFiliale() {
      // console.log('userId', this.form)
      this.$emit('assignToFiliale', this.form);
      this.$refs.modal.hide();
      this.onReset();
    },
    handleModalShown() {
      this.loadFilialePagination({
        status: true,
        pageNumber: 1,
        pageSize: 50,
      });
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset();
    },
    onReset() {
      this.form.employeeUserId = null;
      this.form.isCoTeamLeader = false;
      this.form.isTeamLeader = false;
      this.form.isManager = false;
      this.form.storeId = null;
      this.store = null;
    },
  },
};
</script>

<style lang="scss" scoped>
// form {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }
// .form {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>
