<template>
  <div>
    <b-modal
      id="view-user"
      ref="modal"
      header-class="headerModal"
      centered
      size="lg"
      :title="`${$t('AddNew3')} `"
      hide-footer
      no-close-on-backdrop
      @close="onCancel"
    >
      <b-tabs
        v-model="tabIndex"
        card
      >
        <b-tab
          :title="$t('UserInformation')"
          active
        >
          <template #title>
            <strong style="color: #0071AE;">{{ $t("UserInformation") }}</strong>
          </template>
          <b-card-text><b-form>
            <b-form-group
              id="input-group-6"
              style="font-weight: bold"
              class="mb-0"
            />
            <pre />
            <b-form-group
              id="input-group-5"
              :label="`${$t('Salutation')}:`"
              label-for="input-2"
            >
              <vue-select
                id="input-5"
                v-model="formUser.editUserRequestDto.salutation"
                disabled
                required
                :options="['MR', 'MRS']"
                :placeholder="$t('Select')"
                aria-describedby="input-1-live-feedback"
              />
              <span
                v-show="formSubmitted && !$v.formUser.editUserRequestDto.salutation.required"
                style="color: red"
              >Please select salutation status.</span>
            </b-form-group>
            <b-form-group
              id="input-group-4"
              :label="`${$t('DateofBirth')}:`"
              label-for="input-9"
            >
              <!-- <flat-pickr
                v-model="formUser.editUserRequestDto.dateOfBirth"
                class="form-control"
                :config="configStart"
                placeholder="Select Date"
              /> -->
              <date-picker
                v-model="formUser.editUserRequestDto.dateOfBirth"
                style="width: 100%; "
                value-type="format"
                :placeholder="$t('Select')"
                disabled

                :format="'DD-MM-YYYY'"
              />
              <span
                v-show="formSubmitted && !$v.formUser.editUserRequestDto.dateOfBirth.required"
                style="color: red"
              >{{ $t("AddNew33") }}</span>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              :label="`${$t('FirstName')}:`"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="$v.formUser.editUserRequestDto.firstName.$model"
                :state="validateState('firstName')"
                aria-describedby="input-1-live-feedback"
                disabled
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_be_at_least_3_letters")
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              :label="`${$t('LastName')}:`"
              label-for="input-1"
            >
              <b-form-input
                id="input-2"
                v-model="$v.formUser.editUserRequestDto.lastName.$model"
                :state="validateState('lastName')"
                aria-describedby="input-1-live-feedback"
                disabled
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_be_at_least_3_letters")
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-3"
              :label="`${$t('Email')}:`"
              label-for="input-9"
            >
              <b-form-input
                id="input-3"
                v-model="$v.formUser.editUserRequestDto.email.$model"
                :state="validateState('email')"
                disabled
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_be_at_least_3_letters")
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-6"
              :label="`${$t('PhoneNumber')}:`"
              label-for="input-9"
            >
              <vue-phone-number-input
                id="input-6"
                v-model="formUser.editUserRequestDto.phoneNumber"
                disabled
                default-country-code="CH"
                :error="isPhoneNumberValid == null ? false : isPhoneNumberValid == false ? true : false"
                :preferred-countries="['AL', 'XK', 'DE']"
                @update="getFormattedNumber"
              />
              <span
                v-show="formSubmitted && !$v.formUser.editUserRequestDto.phoneNumber.required"
                style="color: red"
              >{{ $t("AddNew33") }}</span>
            </b-form-group>
            <b-form-group
              id="input-group-5"
              :label="`${$t('EmployeeNationality')}:`"
              label-for="input-2"
            >
              <vue-select
                v-model="formUser.editUserRequestDto.nationality"
                disabled

                :options="countryOptions"
                label="name"
              />
              <span
                v-show="formSubmitted && !$v.formUser.editUserRequestDto.nationality.required"
                style="color: red"
              >{{ $t("AddNew33") }}</span>
            </b-form-group>
            <b-form-group
              v-if="formUser.editUserRequestDto.nationality != 'Switzerland'"
              id="input-group-5"
              :label="`${$t('EmployeeResidenceP')}:`"
              label-for="input-2"
            >
              <vue-select
                id="input-5"
                v-model="formUser.editUserRequestDto.residencePermit"
                disabled

                required
                :options="['B', 'C', 'Ci','G','L','F','N','S']"
                :placeholder="$t('Select')"
                aria-describedby="input-1-live-feedback"
              />
              <span
                v-show="formSubmitted && !$v.formUser.editUserRequestDto.residencePermit.required"
                style="color: red"
              >{{ $t("AddNew33") }}</span>
            </b-form-group>
            <b-form-group
              id="input-group-5"
              :label="`${$t('EmployeeMaritalS')}:`"
              label-for="input-2"
            >
              <vue-select
                id="input-5"
                v-model="formUser.editUserRequestDto.maritalStatus"
                required
                disabled

                :options="[
                  { value: 'Single', label: $t('Single') },
                  { value: 'Married', label: $t('Married') },
                  { value: 'Divorced', label: $t('Divorced') },
                  { value: 'Seperated', label: $t('Seperated') },
                ]"
                :placeholder="$t('Select')"
                aria-describedby="input-1-live-feedback"
              />
              <span
                v-show="formSubmitted && !$v.formUser.editUserRequestDto.maritalStatus.required"
                style="color: red"
              >{{ $t("AddNew33") }}</span>
            </b-form-group>
          </b-form>
          </b-card-text>
        </b-tab>
        <b-tab>
          <template #title>
            <strong style="color: #0071AE;">{{ $t("EmployeeAddress") }}</strong>
          </template>
          <b-card-text><b-form>
            <b-form-group
              id="input-group-11"
              style="font-weight: bold"
              class="mb-0"
            />
            <pre />
            <b-form-group
              id="input-group-3"
              label-for="input-3"
              :label="$t('EmployeeStreet')"
            >
              <b-form-input
                id="input-11"
                v-model="$v.formUser.editAddressRequestDto.street.$model"
                :placeholder="`${$t('Street')}`"
                :state="validateState2('street')"
                disabled

                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_contains_numbers') }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="input-group-6"
              label-for="input-6"
              :label="$t('EmployeeHouseNumber')"
            >
              <b-form-input
                id="input-12"
                v-model="$v.formUser.editAddressRequestDto.houseNumber.$model"
                placeholder="House Number"
                disabled

                :state="validateState2('houseNumber')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_contains_numbers') }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-13"
              label-for="input-6"
              :label="$t('EmployeeDoorNumber')"
            >
              <b-form-input
                id="input-13"
                v-model="$v.formUser.editAddressRequestDto.doorNumber.$model"
                placeholder="Door Number"
                :state="validateState2('doorNumber')"
                disabled

                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_contains_numbers') }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-6"
              label-for="input-6"
              :label="$t('EmployeePostalC')"
            >
              <b-form-input
                id="input-14"
                v-model="$v.formUser.editAddressRequestDto.postalCode.$model"
                :placeholder="`${$t('PostalCode')}`"
                :state="validateState2('postalCode')"
                aria-describedby="input-1-live-feedback"
                disabled
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_be_at_least_5_characters') }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-5"
              label-for="input-5"
              :label="$t('EmployeeCity')"
            >
              <b-form-input
                id="input-15"
                v-model="$v.formUser.editAddressRequestDto.city.$model"
                :state="validateState2('city')"
                disabled

                :placeholder="`${$t('City')}`"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="input-group-"
              label-for="input-7"
              :label="$t('EmployeeCountry')"
            >
              <b-form-input
                id="input-16"
                v-model="$v.formUser.editAddressRequestDto.country.$model"
                :state="validateState2('country')"
                disabled

                :placeholder="`${$t('Country')}`"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-5"
              :label="`${$t('EmployeeCanton')}:`"
              label-for="input-2"
            >
              <vue-select
                id="input-123"
                v-model="canton"
                :options="getCantons"
                :reduce="(e) => e.name"
                disabled

                label="name"
                :placeholder="$t('Select')"
              />
              <!-- <span
                v-show="formSubmitted && !$v.canton.required"
                style="color: red"
              >{{$t("AddNew33")}}</span> -->
            </b-form-group>
          </b-form></b-card-text>
        </b-tab>
        <b-tab>
          <template #title>
            <strong style="color: #0071AE;">{{ $t("EmployeeDocuments") }}</strong>
          </template>
          <div>
            <b-form-group
              id="input-group-6"
              style="font-weight: bold"
              class="mb-0"
            />
            <div
              v-if="imagePassport.length > 0"
            >
              <div class="files">
                <span style="color: #0071AE; font-weight: bold;">{{ $t("Passport") }}</span>
                <div
                  style="display: flex; flex-direction: row !important;"
                >
                  <div
                    v-for="(img, index) in imagePassport"
                    :key="img.photoId"
                    :class="{'second-photo': index === 1, 'third-photo': index === 2,}"
                    style="background-color: transparent; border-radius: 8px; height: 56px; display: flex; justify-content: center; align-items: center;gap: 20px;"
                  >
                    <img
                      :src="img.src"
                      :class="{'photo': true, 'hovered': hoveredIndex === img.photoId, 'second-photo': index === 1, 'third-photo': index === 2}"
                      :style="{ transform: `scale(${img.zoom})` }"
                      alt="Not an image"
                      @click="openLightbox(index)"
                    >
                    <button
                      style="border: 0; width: 48px; height: 36px; background: #FF274F; border-radius: 5px; margin-right: 10px;"
                      @click="downloadFile(img.src)"
                    >
                      <b-icon-download style="color: white;" />

                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="files"
            >
              <span style="color: #0071AE; font-weight: bold;">{{ $t("AddNew26") }}</span>
            </div>
            <div
              v-if="imageResidencePermit.length > 0"
            >
              <div class="files">

                <span style="color: #0071AE; font-weight: bold;">{{ $t("AddNew39") }}</span>

                <div
                  v-for="(img, index) in imageResidencePermit"
                  :key="img.photoId"
                  :class="{'second-photo': index === 1, 'third-photo': index === 2,}"
                  style="background-color: transparent; border-radius: 8px; height: 56px; display: flex; justify-content: center; align-items: center;gap: 20px;"
                >
                  <img
                    :class="{'photo': true, 'hovered': hoveredIndex === img.photoId,'second-photo': index === 1, 'third-photo': index === 2,}"
                    :style="{ transform: `scale(${img.zoom})` }"
                    :src="img.src"
                    alt="Not an image"
                    @click="openLightbox2(index)"
                  >
                  <button
                    style="border: 0; width: 48px; height: 36px; background: #FF274F; border-radius: 5px; margin-right: 10px;"
                    @click="downloadFile(img.src)"
                  >
                    <b-icon-download style="color: white;" />

                  </button>
                </div>
              </div>
            </div>
            <div
              v-else
              class="files"
            >
              <span style="color: #0071AE; font-weight: bold;">{{ $t("AddNew28") }}</span>
            </div>
            <div v-if="imageSocialNumber.length > 0">
              <div
                style="display: flex; flex-direction: row !important;"
              >
                <div class="files">
                  <span style="color: #0071AE; font-weight: bold;">{{ $t("AHV") }}</span>

                  <div
                    v-for="(img, index) in imageSocialNumber"
                    :key="img.photoId"
                    :class="{'second-photo': index === 1, 'third-photo': index === 2,}"
                    style="background-color: transparent; border-radius: 8px; height: 56px; display: flex; justify-content: center; align-items: center;gap: 20px;"
                  >
                    <p style="color: #0071AE; font-weight: bold;margin-bottom: 0px;">
                      AHV : {{ formUser.editUserRequestDto.socialNumber }}
                    </p>
                    <img
                      :class="{'photo': true, 'hovered': hoveredIndex === img.photoId,'second-photo': index === 1, 'third-photo': index === 2,}"
                      :style="{ transform: `scale(${img.zoom})` }"
                      :src="img.src"
                      alt="Not an image"
                      @click="openLightbox1(index)"
                    >
                    <button
                      style="border: 0; width: 48px; height: 36px; background: #FF274F; border-radius: 5px; margin-right: 10px;"
                      @click="downloadFile(img.src)"
                    >
                      <b-icon-download style="color: white;" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="files"
            >
              <p style="color: #0071AE; font-weight: bold;margin-bottom: 0px;">
                AHV : {{ formUser.editUserRequestDto.socialNumber }}
              </p>
              <span style="color: #0071AE; font-weight: bold;">{{ $t("AddNew30") }}</span>
            </div>
          </div>
          <vue-easy-lightbox
            :visible="lightboxVisible"
            :index="lightboxIndex"
            :imgs="imagePassport"
            @hide="closeLightbox"
          />
          <vue-easy-lightbox
            :visible="lightboxVisible1"
            :index="lightboxIndex1"
            :imgs="imageSocialNumber"
            @hide="closeLightbox1"
          />
          <vue-easy-lightbox
            :visible="lightboxVisible2"
            :index="lightboxIndex2"
            :imgs="imageResidencePermit"
            @hide="closeLightbox2"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <strong style="color: #0071AE;">{{ $t("EmployeeWorkInfo") }}</strong>
          </template>
          <b-card-text> <b-form>
            <b-form-group
              id="input-group-6"
              style="font-weight: bold"
              class="mb-0"
            />
            <pre />
            <b-form-group
              id="input-group-8"
              :label="$t('VacationEmployeeT')"
              label-for="input-25"
            >
              <vue-select
                id="input-8"
                v-model="formUser.editUserRequestDto.employeeType"

                disabled
                :options="[
                  {value:'Internal', label:$t('Internal')},
                  {value:'External',label:$t('External')},
                ]"
                :reduce="(e) => e.value"
                aria-describedby="input-1-live-feedback"
              />
              <span
                v-show="formSubmitted && !$v.formUser.editUserRequestDto.employeeType.required"
                style="color: red"
              >Please select a date.</span>
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-7"
              :label="$t('VacationRole')"
              label-for="input-26"
            >
              <vue-select
                id="input-7"
                v-model="formUser.editUserRequestDto.personRole"
                :options="roleOptions"
                disabled
              />
              <span
                v-show="formSubmitted && !$v.formUser.editUserRequestDto.personRole.required"
                style="color: red"
              >{{ $t("AddNew33") }}</span>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              :label="$t('EmploSalaryyee')"
              label-for="input-27"
            >
              <b-form-input
                id="input-27"
                v-model.number="$v.formUser.editEmployeeWorkOverviewRequestDto.salary.$model"
                :state="validateState3('salary')"
                aria-describedby="input-1-live-feedback"
                disabled
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_be_at_least_3_letters")
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-checkbox
              id="checkbox-1"
              v-model="formUser.editEmployeeWorkOverviewRequestDto.thirteenSalary"
              name="checkbox-1"
              :value="true"
              disabled
              :unchecked-value="false"
            >
              {{ $t("EmployeeThirteen") }}
            </b-form-checkbox>
            <b-form-group
              id="input-group-1"
              :label="$t('EmployeeSalaryChanges')"
              label-for="input-27"
            >
              <b-form-input
                id="input-27"
                v-model="$v.formUser.editEmployeeWorkOverviewRequestDto.salaryChanges.$model"
                :state="validateState3('salaryChanges')"
                aria-describedby="input-1-live-feedback"
                disabled
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_be_at_least_3_letters")
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              :label="$t('EmployeeChildAllowances')"
              label-for="input-27"
            >
              <b-form-input
                id="input-27"
                v-model="$v.formUser.editEmployeeWorkOverviewRequestDto.childAllowances.$model"
                :state="validateState3('childAllowances')"
                aria-describedby="input-1-live-feedback"
                disabled
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_be_at_least_3_letters")
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              :label="$t('EmployeeStartingDay')"
              label-for="input-1"
            >
              <!-- <flat-pickr
                v-model="formUser.editEmployeeWorkOverviewRequestDto.startDate"
                class="form-control"
                :config="configStart"
                placeholder="Select Date"
              /> -->
              <date-picker
                v-model="formUser.editEmployeeWorkOverviewRequestDto.startDate"
                style="width: 100%"
                value-type="format"
                :placeholder="$t('Select')"
                disabled
                :format="'DD-MM-YYYY'"
              />
              <span
                v-show="formSubmitted && !$v.formUser.editEmployeeWorkOverviewRequestDto.startDate.required"
                style="color: red"
              >{{ $t("AddNew33") }}</span>
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              :label="$t('EmployeeEndingDay')"
              label-for="input-1"
            >
              <!-- <flat-pickr
                v-model="formUser.editEmployeeWorkOverviewRequestDto.endDate"
                class="form-control"
                :config="configStart"
                placeholder="Select Date"
              /> -->
              <date-picker
                v-model="formUser.editEmployeeWorkOverviewRequestDto.endDate"
                style="width: 100%"
                value-type="format"
                disabled
                :placeholder="$t('Select')"
                :format="'DD-MM-YYYY'"
              />

              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
            </b-form-group>
            <!-- <pre v-if="showError" />
            <p
              v-if="showError"
              style="color:#ff9966 ; font-size: smaller;"
            >
              Important! <br> Endind date should be after starting date!
            </p> -->
            <b-form-group
              id="input-group-1"
              :label="$t('EmployeeWorkload')"
              label-for="input-23"
            >
              <vue-select
                id="input-22"
                v-model="formUser.editEmployeeWorkOverviewRequestDto.workload"
                :options="[20, 40, 50, 60, 80, 100]"
                aria-describedby="input-1-live-feedback"
                disabled
              />
              <span
                v-show="formSubmitted && !$v.formUser.editEmployeeWorkOverviewRequestDto.workload.required"
                style="color: red"
              >{{ $t("AddNew33") }}</span>
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
            </b-form-group>
            <!-- v-if="getUserInformation.userResponseDto.personRole != 'CEO'" -->
            <b-form-group
              id="input-group-1"
              :label="$t('Filiale')"
              label-for="input-23"
            >
              <vue-select
                id="input-22321"
                v-model="updateStoreObject.storeId"
                :options="getFiliales"
                :reduce="(e) => e.storeId"
                label="storeName"
                disabled
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </b-form></b-card-text>
        </b-tab></b-tabs>
      <!-- <b-form>

        <b-form class="form2">
          <b-button
            v-if="tabIndex == 3"
            type="button"
            variant="none"
            class="buttonSubmit"
            style="margin-left: 15px"
            @click="onSubmitAddUser"
          >
            {{ $t('VacationSubmit') }}
          </b-button>
          <b-button
            v-else
            class="buttonSubmit"
            style="margin-left: 15px"
            @click="tabIndex++"
          >
            {{ $t('EmployeeNext') }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 90px"
            @click="onCancel"
          >
            {{ $t('VacationCancel') }}
          </b-button>
        </b-form>
      </b-form> -->
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import flatPickr from 'vue-flatpickr-component';
import { validationMixin } from 'vuelidate';
import AWS from 'aws-sdk';
// import CircularJSON from 'circular-json';
import moment from 'moment'
import {
  required, email, minLength, numeric,
} from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
import VueEasyLightbox from 'vue-easy-lightbox';
import { AWSurl } from '../../../domainConfig';
import 'vue2-datepicker/index.css';



export default {
  components: {
    // flatPickr,
    DatePicker,
    VueEasyLightbox,
  },
  mixins: [validationMixin],
  data() {
    return {
      visible: false,
      lightboxVisible: false,
      lightboxIndex: 0,
      lightboxVisible1: false,
      lightboxIndex1: 0,
      lightboxVisible2: false,
      lightboxIndex2: 0,
      userId: null,
      imagePassport: [],
      imageSocialNumber: [],
      imageResidencePermit: [],
      formSubmitted: false,
      deleteButton: false,
      isTextShown: false,
      hoveredIndex: null,
      text: 'Click to zoom the photo',
      countryOptions: ['Switzerland', 'Germany', 'France', 'Austria', 'Kosovo', 'Albania', 'North Macedonia', 'Serbia', 'Croatia', // Kosovo's ISO 3166-1 alpha-2 code is 'XK'
      ],
      tabIndex: 1,
      showError: false,
      configStart: {
        altInput: true,
        altFormat: 'd-m-Y',
        minDate: new Date(),
        dateFormat: 'Y-m-d',
      },
      isPhoneNumberValid: null,
      roleOptions: ['CEO', 'Manager', 'TeamLeader', 'CoTeamLeader', 'Employee'],
      s3: null,
      bucketName: AWSurl.bucketName,
      canton: [],
      fileDataPassport: [],
      fileDataAufenthaltsbewilligung: [],
      fileDataAHV: [],
      updateStoreObject: {
        storeId: '',
        employeeUserId: '',
        isManager: false,
        isTeamLeader: false,
        isCoTeamLeader: false,
      },
      formUser: {
        editUserRequestDto: {
          userId: '',
          firstName: '',
          lastName: '',
          salutation: '',
          personRole: '',
          countryCode: '',
          phoneNumber: '',
          email: '',
          socialNumber: '',
          dateOfBirth: '',
          maritalStatus: '',
          residencePermit: '',
          nationality: '',
          employeeType: '',
        },
        editAddressRequestDto: {
          street: '',
          houseNumber: '',
          doorNumber: '',
          postalCode: '',
          city: '',
          country: '',
          countryState: '',
        },
        editEmployeeWorkOverviewRequestDto: {
          startDate: '',
          endDate: '2999-01-31',
          workload: 0,
          salary: 0,
          thirteenSalary: false,
          childAllowances: 0,
          salaryChanges: 0,
        },
        editDocumentRequestDto: [
        ],
      },
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
      salutation: ['Mr.', 'Ms.', 'BA.', 'BSc.', 'DI.', 'MA.', 'MSc.', 'MBA.', 'PhD.'],
    };
  },
  validations: {
    formUser: {
      editUserRequestDto: {
        firstName: {
          required,
          minLength: minLength(3),
        },
        lastName: {
          required,
          minLength: minLength(3),

        },
        salutation: {
          required,
        },
        personRole: {
          required,
        },
        countryCode: {
          required,
        },
        phoneNumber: {
          required,
        },
        email: {
          required,
          email,
        },
        socialNumber: {
          required,
        },
        dateOfBirth: {
          required,
        },
        maritalStatus: {
          required,
        },
        residencePermit: {
          required,
        },
        nationality: {
          required,
        },
        employeeType: {
          required,
        },
        numberOfVacationDays: {
        },
      },
      editAddressRequestDto: {
        street: {
          required,
          minLength: minLength(3),
        },
        houseNumber: {
          required,
          minLength: minLength(1),
          numeric,
        },
        postalCode: {
          required,
          numeric,
        },
        doorNumber: {
          required,
          numeric,
        },
        city: {
          required,
          minLength: minLength(3),
        },
        country: {
          required,
          minLength: minLength(3),
        },
      },
      editEmployeeWorkOverviewRequestDto: {
        salary: {
          required,
        },
        salaryChanges: {
          required,
        },
        childAllowances: {
          required,
        },
        startDate: {
          required,
        },
        endDate: {
          required,
        },
        workload: {
          required,
        },
      },
      // newDocumentRequestDto: {},
    },
    canton: {
      required,
    },
    // myFiles: {
    //   required,
    //   maxLength: maxLength(1),
    // },
    // dateOfBirth: {
    //   required,
    // },
    // time: {
    //   required,
    // },
  },
  computed: {
    ...mapGetters([
      'getCantons',
      'getUserInformation',
      'getFiliales',
      'getisOnTour',
    ]),
    userById() {
      return this.$store.getters.getUserInformation;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'formUser.editEmployeeWorkOverviewRequestDto.startDate': {
      handler(value) {
        if (value > this.formUser.editEmployeeWorkOverviewRequestDto.endDate) {
          this.showError = true;
        } else {
          this.showError = false;
        }
      },
      immediate: true,
    },
    'formUser.editUserRequestDto.personRole': {
      handler(value) {
        if (value === 'Manager') {
          this.updateStoreObject.isManager = true;
          this.updateStoreObject.isTeamLeader = false;
          this.updateStoreObject.isCoTeamLeader = false;
        } else if (value === 'TeamLeader') {
          this.updateStoreObject.isManager = false;
          this.updateStoreObject.isTeamLeader = true;
          this.updateStoreObject.isCoTeamLeader = false;
        } else if (value === 'CoTeamLeader') {
          this.updateStoreObject.isManager = false;
          this.updateStoreObject.isTeamLeader = false;
          this.updateStoreObject.isCoTeamLeader = true;
        } else {
          this.updateStoreObject.isManager = false;
          this.updateStoreObject.isTeamLeader = false;
          this.updateStoreObject.isCoTeamLeader = false;
        }
      },
    },
    'formUser.editUserRequestDto.nationality': {
      handler(value) {
        if (value === 'Switzerland') {
          this.formUser.editUserRequestDto.residencePermit = '';
        }
      },
      immediate: true,
    },
    'formUser.editEmployeeWorkOverviewRequestDto.endDate': {
      handler(value) {
        if (value < this.formUser.editEmployeeWorkOverviewRequestDto.startDate) {
          this.showError = true;
        } else {
          this.showError = false;
        }
      },
      immediate: true,
    },
    canton: {
      handler(value) {
        this.formUser.editAddressRequestDto.countryState = value;
      },
      immediate: true,
    },
    userById: {
      // immediate: true, // update the data property immediately on component mount
      handler(newVal) {
        this.userId = newVal.userResponseDto.userId
        // this.storeId = newVal.storeId;
        this.formUser.editUserRequestDto = {
          userId: newVal.userResponseDto.userId,
          firstName: newVal.userResponseDto.firstName,
          lastName: newVal.userResponseDto.lastName,
          salutation: newVal.userResponseDto.salutation,
          personRole: newVal.userResponseDto.personRole,
          countryCode: newVal.userResponseDto.countryCode,
          phoneNumber: newVal.userResponseDto.phoneNumber,
          email: newVal.userResponseDto.email,
          socialNumber: newVal.userResponseDto.socialNumber,
          dateOfBirth: moment(newVal.userResponseDto.dateOfBirth).format('DD-MM-YYYY'),
          maritalStatus: newVal.userResponseDto.maritalStatus,
          residencePermit: newVal.userResponseDto.residencePermit,
          nationality: newVal.userResponseDto.nationality,
          employeeType: newVal.userResponseDto.employeeType,
        }
        this.formUser.editAddressRequestDto = {
          street: newVal.addressResponseDto[0].street,
          houseNumber: newVal.addressResponseDto[0].houseNumber,
          doorNumber: newVal.addressResponseDto[0].doorNumber,
          postalCode: newVal.addressResponseDto[0].postalCode,
          city: newVal.addressResponseDto[0].city,
          country: newVal.addressResponseDto[0].country,
          countryState: newVal.addressResponseDto[0].countryState,
        }
        this.formUser.editEmployeeWorkOverviewRequestDto = {
          startDate: moment(newVal.employeeWorkOverviewResponseDto.startDate).format('DD-MM-YYYY'),
          endDate: moment(newVal.employeeWorkOverviewResponseDto.endDate).format('DD-MM-YYYY'),
          workload: newVal.employeeWorkOverviewResponseDto.workload,
          salary: newVal.employeeWorkOverviewResponseDto.salary,
          thirteenSalary: newVal.employeeWorkOverviewResponseDto.thirteenSalary,
          childAllowances: newVal.employeeWorkOverviewResponseDto.childAllowances,
          salaryChanges: newVal.employeeWorkOverviewResponseDto.salaryChanges,
        }
        this.updateStoreObject.storeId = newVal.storeName;
        this.updateStoreObject.employeeUserId = newVal.userResponseDto.userId;
        this.canton = newVal.addressResponseDto[0].countryState;
        // create an object to store arrays based on documentType
        for (let i = 0; i < newVal.documentFullResponseDto.length; i++) {
          const document = newVal.documentFullResponseDto[i];
          const documentType = document.documentType;
          for (let j = 0; j < document.documentPhotosResponseDtos.length; j++) {
            const photo = document.documentPhotosResponseDtos[j];
            const image = {
              src: photo.url,
              title: photo.fileName,
              documentId: document.documentId,
              photoId: photo.photoId,
              documentType: document.documentType,
              expiryDate: document.expiryDate,
            };
            let imageArray;
            if (documentType === 'Passport') {
              imageArray = this.imagePassport;
            } else if (documentType === 'ResidencePermit') {
              imageArray = this.imageResidencePermit;
            } else if (documentType === 'SocialNumber') {
              imageArray = this.imageSocialNumber;
            }
            const index = imageArray.findIndex(
              (existingImage) => {
                return existingImage.photoId === photo.photoId
        && existingImage.documentId === document.documentId
              },
            );
            if (index === -1) {
              imageArray.push(image);
            } else {
              // Replace the existing image object with the new one
              imageArray.splice(index, 1, image);
            }
          }
        }

        // access the arrays based on documentType
      },
    },
  },
  created() {
    AWS.config.update({
      accessKeyId: AWSurl.accessKeyId,
      secretAccessKey: AWSurl.secretAccessKey,
      region: AWSurl.region,
    });

    this.s3 = new AWS.S3();
    this.loadCountryStates();
  },
  methods: {
    ...mapActions([
      'loadCountryStates',
      'deleteImage',
      'loadFilialePagination',
      'updateStoreOfEmployee',
      'busyTruckStuffByEmployeeUserIdStartShift',
    ]),
    downloadFile(fileUrl) {
      // Extract filename from the URL
      const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);

      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;

      // Trigger a click on the anchor element to start the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    openLightbox(index) {
      this.lightboxIndex = index;
      this.lightboxVisible = true;
    },
    closeLightbox() {
      this.lightboxVisible = false;
    },
    openLightbox1(index) {
      this.lightboxIndex1 = index;
      this.lightboxVisible1 = true;
    },
    closeLightbox1() {
      this.lightboxVisible1 = false;
    },
    openLightbox2(index) {
      this.lightboxIndex2 = index;
      this.lightboxVisible2 = true;
    },
    closeLightbox2() {
      this.lightboxVisible2 = false;
    },
    handleHide() {
      this.visible = false
    },
    show() {
      this.visible = true
    },
    handleModalShown() {
      const today = new Date().toLocaleDateString('en-CA');
      this.loadFilialePagination({
        status: true,
        pageNumber: 1,
        pageSize: 50,
      });
      this.busyTruckStuffByEmployeeUserIdStartShift({
        employeeUserId: this.$store.getters.getUserInformation.userResponseDto.userId,
        startShift: today,
        endShift: null,
      })
    },
    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true
        this.formUser.editUserRequestDto.phoneNumber = n.nationalNumber
        this.formUser.editUserRequestDto.countryCode = n.countryCallingCode
      } else {
        this.formUser.editUserRequestDto.countryCode = ''
        this.isPhoneNumberValid = false
        return;
      }
      console.log()
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.formUser.editUserRequestDto[name];
      return $dirty ? !$error : null;
    },
    validateState2(name) {
      const { $dirty, $error } = this.$v.formUser.editAddressRequestDto[name];
      return $dirty ? !$error : null;
    },
    validateState3(name) {
      const { $dirty, $error } = this.$v.formUser.editEmployeeWorkOverviewRequestDto[name];
      return $dirty ? !$error : null;
    },
    onChangePassport(event) {
      this.fileDataPassport = [];
      const files = event.target.files;
      if (files.length <= 3) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileContent = new FileReader();
          fileContent.readAsArrayBuffer(file);

          const promise = new Promise((resolve) => {
            fileContent.onload = (e) => {
              const fileData = {
                fileContent: Buffer.from(e.target.result),
                filetype: file.type.split('/')[1],
                fileName: file.name.split('.')[0],
              }
              resolve(fileData);
            };
          });

          promise.then((fileData) => {
            this.fileDataPassport.push(fileData);
          });

          // console.log(this.fileDataPassport, 'fasfa');
        }
      } else {
        console.error('You can only select a maximum of 3 files.')
      }
    },
    onChangeAufenthaltsbewilligung(event) {
      this.fileDataAufenthaltsbewilligung = []
      const files = event.target.files;
      if (files.length <= 3) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileContent = new FileReader();
          fileContent.readAsArrayBuffer(file);

          const promise = new Promise((resolve) => {
            fileContent.onload = (e) => {
              const fileData = {
                fileContent: Buffer.from(e.target.result),
                filetype: file.type.split('/')[1],
                fileName: file.name.split('.')[0],
              }
              resolve(fileData);
            };
          });

          promise.then((fileData) => {
            this.fileDataAufenthaltsbewilligung.push(fileData);
          });

          // console.log(this.fileData, 'fasfa');
        }
      } else {
        console.error('You can only select a maximum of 3 files.')
      }
    },
    onChangeAHV(event) {
      this.fileDataAHV = []
      const files = event.target.files;
      if (files.length <= 3) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileContent = new FileReader();
          fileContent.readAsArrayBuffer(file);

          const promise = new Promise((resolve) => {
            fileContent.onload = (e) => {
              const fileData = {
                fileContent: Buffer.from(e.target.result),
                filetype: file.type.split('/')[1],
                fileName: file.name.split('.')[0],
              }
              resolve(fileData);
            };
          });

          promise.then((fileData) => {
            this.fileDataAHV.push(fileData);
          });

          // console.log(this.fileData, 'fasfa');
        }
      } else {
        console.error('You can only select a maximum of 3 files.')
      }
    },
    uploadFileToS3Passport(fileData) {
      const params = {
        Bucket: this.bucketName,
        Key: `${Date.now()}-${fileData.fileName}.${fileData.filetype}`,
        Body: fileData.fileContent,
        ContentType: fileData.filetype,
      }
      const passportDocumentKey = params.Key;
      const passportDocObject = this.formUser.editDocumentRequestDto.find((doc) => { return doc.documentType === 'Passport' });
      if (passportDocObject) {
        passportDocObject.key.push(passportDocumentKey); // add the new passport document key to the key property of the Passport object
      } else {
        const newDocumentObject = { // create a new object with the new passport document key
          documentType: 'Passport',
          expiryDate: '2023-12-12',
          key: [passportDocumentKey],
        };
        this.formUser.editDocumentRequestDto.push(newDocumentObject); // push the new object to the array
      }
      this.s3.putObject(params, (error) => {
        if (error) {
          console.error(error)
        } else {
          console.log('File uploaded successfully to S3')
        }
      })
    },
    uploadFileToS3Aufenthaltsbewilligung(fileData) {
      const params = {
        Bucket: this.bucketName,
        Key: `${Date.now()}-${fileData.fileName}.${fileData.filetype}`,
        Body: fileData.fileContent,
        ContentType: fileData.filetype,
      }
      const aufenthaltsbewilligungDocumentKey = params.Key;
      const aufenthaltsbewilligungDocObject = this.formUser.editDocumentRequestDto.find((doc) => { return doc.documentType === 'ResidencePermit' });
      if (aufenthaltsbewilligungDocObject) {
        aufenthaltsbewilligungDocObject.key.push(aufenthaltsbewilligungDocumentKey); // add the new passport document key to the key property of the Passport object
      } else {
        const newDocumentObject = { // create a new object with the new passport document key
          documentType: 'ResidencePermit',
          expiryDate: '2023-12-12',
          key: [aufenthaltsbewilligungDocumentKey],
        };
        this.formUser.editDocumentRequestDto.push(newDocumentObject); // push the new object to the array
      }
      this.s3.putObject(params, (error) => {
        if (error) {
          console.error(error)
        } else {
          console.log('File uploaded successfully to S3')
        }
      })
    },
    uploadFileToS3AHV(fileData) {
      const params = {
        Bucket: this.bucketName,
        Key: `${Date.now()}-${fileData.fileName}.${fileData.filetype}`,
        Body: fileData.fileContent,
        ContentType: fileData.filetype,
      }
      const ahvDocumentKey = params.Key;
      const ahvDocObject = this.formUser.editDocumentRequestDto.find((doc) => { return doc.documentType === 'SocialNumber' });
      if (ahvDocObject) {
        ahvDocObject.key.push(ahvDocumentKey); // add the new passport document key to the key property of the Passport object
      } else {
        const newDocumentObject = { // create a new object with the new passport document key
          documentType: 'SocialNumber',
          expiryDate: '2023-12-12',
          key: [ahvDocumentKey],
        };
        this.formUser.editDocumentRequestDto.push(newDocumentObject); // push the new object to the array
      }
      this.s3.putObject(params, (error) => {
        if (error) {
          console.error(error)
        } else {
          console.log('File uploaded successfully to S3')
        }
      })
    },
    async onSubmitAddUser() {
      this.formSubmitted = true;
      // this.$v.formUser.editUserRequestDto.$touch();
      // if (this.$v.formUser.editUserRequestDto.$anyError) {
      //   return;
      // }
      // this.$v.formUser.editAddressRequestDto.$touch();
      // if (this.$v.formUser.editAddressRequestDto.$anyError) {
      //   return;
      // }
      // this.$v.formUser.editEmployeeWorkOverviewRequestDto.$touch();
      // if (this.$v.formUser.editEmployeeWorkOverviewRequestDto.$anyError) {
      //   return;
      // }

      const firstRegistration = moment(this.formUser.editUserRequestDto.dateOfBirth, 'DD-MM-YYYY').format('YYYY-MM-DD');
      this.formUser.editUserRequestDto.dateOfBirth = firstRegistration;

      const insurance = moment(this.formUser.editEmployeeWorkOverviewRequestDto.startDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
      this.formUser.editEmployeeWorkOverviewRequestDto.startDate = insurance;
      const insurancee = moment(this.formUser.editEmployeeWorkOverviewRequestDto.endDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
      this.formUser.editEmployeeWorkOverviewRequestDto.endDate = insurancee;
      // eslint-disable-next-line no-restricted-syntax
      for (const fileData1 of this.fileDataPassport) {
        // eslint-disable-next-line no-await-in-loop
        await this.uploadFileToS3Passport(fileData1);
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const fileData2 of this.fileDataAufenthaltsbewilligung) {
        // eslint-disable-next-line no-await-in-loop
        await this.uploadFileToS3Aufenthaltsbewilligung(fileData2);
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const fileData3 of this.fileDataAHV) {
        // eslint-disable-next-line no-await-in-loop
        await this.uploadFileToS3AHV(fileData3);
      }
      // console.log('FORM', this.formUser)
      this.$emit('pushFormUser', this.formUser)
      await this.updateStoreOfEmployee({
        object: this.updateStoreObject,
        successCallback: () => {
          this.$refs.modal.hide()
          this.getUsersPagination({
            status: this.status,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          });
        },
      });
      this.$refs.modal.hide()
      this.onReset();
      // setTimeout(() => { this.$v.$reset() }, 0)
      // this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset();
    },
    onReset() {
      // Reset our form values
      this.formUser.editUserRequestDto.firstName = '';
      this.formUser.editUserRequestDto.lastName = '';
      this.formUser.editUserRequestDto.email = '';
      this.formUser.editUserRequestDto.dateOfBirth = '';
      this.formUser.editUserRequestDto.salutation = '';
      this.formUser.editUserRequestDto.phoneNumber = '';
      this.formUser.editUserRequestDto.countryCode = '';
      this.formUser.editUserRequestDto.personRole = '';
      this.formUser.editUserRequestDto.employeeType = '';
      this.formUser.editAddressRequestDto.street = '';
      this.formUser.editAddressRequestDto.houseNumber = '';
      this.formUser.editAddressRequestDto.doorNumber = '';
      this.formUser.editAddressRequestDto.postalCode = '';
      this.formUser.editAddressRequestDto.city = '';
      this.formUser.editAddressRequestDto.country = '';
      this.formUser.editEmployeeWorkOverviewRequestDto.startDate = '';
      this.formUser.editEmployeeWorkOverviewRequestDto.endDate = '2999-01-31';
      this.formUser.editEmployeeWorkOverviewRequestDto.workload = 0;
      this.formUser.editEmployeeWorkOverviewRequestDto.salary = 0;
      this.formUser.editDocumentRequestDto = [];
      this.updateStoreObject.isManager = false;
      this.updateStoreObject.isTeamLeader = false;
      this.updateStoreObject.isCoTeamLeader = false;
      // this.form.fileName = []
      this.fileData = []
      this.isTextShown = false;
      this.imagePassport = [];
      this.imageSocialNumber = [];
      this.imageResidencePermit = [];
      this.fileDataPassport = [];
      this.fileDataAufenthaltsbewilligung = [];
      this.fileDataAHV = [];
      // Trick to reset/clear native browser form validation state
    },
    handleDeleteClick(photoId) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this photo?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then((value) => {
          if (value) {
            this.deletePhoto(photoId);
          }
        })
        .catch((err) => {
          console.log('Error in msgBoxConfirm:', err);
        });
    },
    async deletePhoto(value) {
      await this.deleteImage({
        Id: value,
        successCallback: () => {
          this.imagePassport = [];
          this.imageSocialNumber = [];
          this.imageResidencePermit = [];
          this.$emit('getPhotos', this.userId)
        },
      })
    },
    zoomIn(index) {
      this.hoveredIndex = index;
    },
    zoomOut() {
      this.hoveredIndex = null;
    },
    showText() {
      this.isTextShown = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.files {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    background-color: rgb(207, 207, 207);
    border: none;
    box-shadow: none;
    padding: 12px;
    color: #212529;
    border-radius: 8px;
    width: 100%;
    margin-right: 10px;
    justify-content: space-between;
    align-items: center;
}
 .photo{
    margin-left: 5px;
    height: 50px;
    border-radius: 8px;
  }
.second-photo{
  margin-left: 5px;
}
.hovered {
  /* Additional styles for the hovered photo */
  transform: scale(7.2);
  border-radius: 0px;
  z-index: 10;
}
.third-photo{
  margin-left: 5px;
}
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: flex-end;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr ;
  gap: 10px;
}
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr ;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons{
  display: flex;
  align-items:flex-end;
}
</style>
