<template>
  <div class="home">
    <section
      class="main no-flex"
      style="margin-top: 13px;"
    >

      <UsersListing
        @click="handleUser"
        @show-other-component="showOtherComponent = true"
        @show-the-component="showOtherComponent = false"
      />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import UsersListing from '@/components/employees/UsersListing.vue'
import { mapGetters } from 'vuex'


export default {
  name: 'Home',
  components: {
    UsersListing,
  },
  data() {
    return {
      user: '',
      showOtherComponent: false,
    }
  },
  computed: {
    ...mapGetters(['getIsLoading']),

  },
  methods: {
    materialId(id) {
      this.materialIdToShow = id
    },
    handleUser(id) {
      this.user = id;
    },
  },
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 1800px){
  .main{
    padding: 90px 15px 15px 110px;
  }
}
@media screen and (max-width: 1200px){
  .main{
    padding: 90px 15px 15px 90px;
  }
}
@media screen and (max-width: 1041px) {
  .main {
    padding: 90px 0.75rem 15px 0.75rem;
  }
}
</style>
